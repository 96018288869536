/* tslint:disable */

import { TranslationFormat } from './Hilti.PE.Core.Common.Entities.Translation';
import { EntityBase } from './Hilti.PE.Core.Entities';
import { CalculationResultEntity } from './Hilti.PE.Core.Entities.Baseplate.Calculation';
import { UtilizationValueEntity } from './Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import { ProjectDesignCodeLists } from './Hilti.PE.Core.Entities.Baseplate.Codelists';
import { AnchorFilterGroupOperator } from './Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { CheckboxFilter } from './Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    LoadCharacteristic,
    LoadType,
    SeismicShearType,
    SeismicTensionType
} from './Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { Navigation } from './Hilti.PE.Core.Entities.Navigation';

export interface AnchorFilter {
    CheckboxFilters: CheckboxFilter[];
    CheckboxFiltersDisabled: CheckboxFilter[];
    CheckboxFiltersRemoved: CheckboxFilter[];
    MinMaxFilters: AnchorFilterMinMax;
}

export interface AnchorFilterCheckboxGroup {
    GroupOperator: AnchorFilterGroupOperator;
    Id: number;
    Items: AnchorFilterCheckboxGroupItem[];
}

export interface AnchorFilterCheckboxGroupItem {
    Id: number;
    Selected: boolean;
    Title: string;
}
export interface AnchorFilterMinMax {
    FixtureThicknesMax: number;
    FixtureThicknesMin: number;
    HoleDiameterMax: number;
    HoleDiameterMin: number;
}
export interface BaseplateSize {
    Diameter: number;
    DiameterVisible: boolean;
    Height: number;
    HeightVisible: boolean;
    SideLength: number;
    SideLengthVisible: boolean;
    Thickness: number;
    ThicknessVisible: boolean;
    Width: number;
    WidthVisible: boolean;
}
export interface BrickSize {
    Height: number;
    Thickness: number;
    Width: number;
}
export interface DefaultsOfUserSettings {
    AddressDefault: string;
    AnchorPlateFactorDefault: number;
    AnchorPlateThicknessDefault: number;
    AuthorDefault: string;
    CompanyNameDefault: string;
    EmailDefault: string;
    FaxNumberDefault: string;
    MinimumAnchorToProfileDistance: number;
    MinimumConcreteCover: number;
    PermanentLoadsDefault: number;
    PhoneNumberDefault: string;
    VariableLoadsDefault: number;
}
export interface FeatureVisibilityInfoEntity extends EntityBase {
    Disabled?: boolean;
    DisabledRegions: number[];
    Feature: number;
    Hidden?: boolean;
    TooltipDisplayKey: string;
}

export interface ImportLoadCombinations {
    HasSustainedLoads: boolean;
}
export interface InitialDataEntity extends EntityBase {
    AllowedAnchorAIRegions: number[];
    ApplicationVersion: string;
    FeaturesVisibilityInfo: FeatureVisibilityInfoEntity[];
    IsAnchorAiDebugEnabled: boolean;
    IsAnchorAiEnabled: boolean;
    NavigationStructure: Navigation;
    ProjectDesignCodeLists: ProjectDesignCodeLists;
}
export interface LoadCombination {
    ActiveLoadType: LoadType;
    Combination?: UtilizationValueEntity;
    Description: string;
    DynamicForceX: number;
    DynamicForceY: number;
    DynamicForceZ: number;
    DynamicMomentX: number;
    DynamicMomentY: number;
    DynamicMomentZ: number;
    ForceX: number;
    ForceY: number;
    ForceZ: number;
    HasSustainedLoads: boolean;
    Id: string;
    IsWizardGenerated: boolean;
    LoadCharacteristic: LoadCharacteristic;
    MomentX: number;
    MomentY: number;
    MomentZ: number;
    Name: string;
    ResultMessages: TranslationFormat[];
    Shear?: UtilizationValueEntity;
    Tension?: UtilizationValueEntity;
}

export interface LoadCombinationHandrail extends LoadCombination {
    StructuralSafetyConnectionPlates?: UtilizationValueEntity;
    StructuralSafetyConnectionWelds?: UtilizationValueEntity;
    StructuralSafetyForDisplay?: number;
    StructuralSafetyPost?: UtilizationValueEntity;
    StructuralSafetyRail?: UtilizationValueEntity;
    TotalDeflection?: UtilizationValueEntity;
}
export interface LoadCombinationHNAWizard {
    LoadCombinationHNAWizardEquations: number[];
    LoadCombinationHNAWizardFactors: LoadCombinationHNAWizardFactors[];
    SeismicDesign: boolean;
    SeismicShearType: SeismicShearType;
    SeismicTensionType: SeismicTensionType;
}

export interface LoadCombinationHNAWizardFactors {
    ForceX: number;
    ForceY: number;
    ForceZ: number;
    Id: number;
    LoadFactorId: number;
    MomentX: number;
    MomentY: number;
    MomentZ: number;
    SustainedLoadFactor: number;
}
export interface StaticDesignData {
    AnchorsMarkedAsNew: number[];
    CalculationResults: CalculationResultEntity;
}

export enum UIProperty {
    None = 0,
    AdditionalFiltersButton = 3200,
    AdvancedBaseplateCalculation_AlphaCC = 1052,
    AdvancedBaseplateCalculation_ConcreteInCompressionMethod = 2006,
    AdvancedBaseplateCalculation_DivergentIterationsCount = 1015,
    AdvancedBaseplateCalculation_DivisionOfArcsOfRHS = 1012,
    AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS = 1011,
    AdvancedBaseplateCalculation_EffectiveArea = 1003,
    AdvancedBaseplateCalculation_EffectiveAreaAISC = 880,
    AdvancedBaseplateCalculation_JointCoefficientBj = 1002,
    AdvancedBaseplateCalculation_LimitPlasticStrain = 1005,
    AdvancedBaseplateCalculation_LoadDistributionAngle = 1004,
    AdvancedBaseplateCalculation_MaximumSizeOfElement = 1017,
    AdvancedBaseplateCalculation_MinimumSizeOfElement = 1016,
    AdvancedBaseplateCalculation_NumberOfAnalysisIterations = 1014,
    AdvancedBaseplateCalculation_NumberOfElementsOfEdge = 1013,
    AdvancedBaseplateCalculation_StopAtLimitStrain = 1001,
    AdvancedBaseplateCalculation_UseULSStresses = 1053,
    AnchorLayout_AnchorFamily = 201,
    AnchorLayout_AnchorFilters = 216,
    AnchorLayout_AnchorLayoutSymmetry = 2260,
    AnchorLayout_AnchorTorqueType = 450,
    AnchorLayout_ApprovalLanguages = 851,
    AnchorLayout_ApprovalLanguages_STO = 1057,
    AnchorLayout_ApprovalLanguages_UKTA = 2258,
    AnchorLayout_ArticleNumberCapsule = 790,
    AnchorLayout_ArticleNumberChemical = 779,
    AnchorLayout_ArticleNumberInsert = 789,
    AnchorLayout_ArticleNumberInsertAlternative = 2262,
    AnchorLayout_ArticleNumberMechanical = 778,
    AnchorLayout_ArticleNumberSieveSleeve = 811,
    AnchorLayout_CipAh = 2123,
    AnchorLayout_CipK8 = 2103,
    AnchorLayout_CipMinimumEdgeDistance = 2102,
    AnchorLayout_CipMinSpacing = 2101,
    AnchorLayout_CmdRotateAnticlockwise = 204,
    AnchorLayout_CmdRotateClockwise = 203,
    AnchorLayout_CustomLayoutPoints = 215,
    AnchorLayout_ElasticModulus = 2000,
    AnchorLayout_ElongationLength = 2002,
    AnchorLayout_EmbedmentDepthFixedMultiple = 213,
    AnchorLayout_EmbedmentDepthOptimizationType = 214,
    AnchorLayout_EmbedmentDepthVariable = 212,
    AnchorLayout_Fastener = 2059,
    AnchorLayout_FillHolesETAG = 206,
    AnchorLayout_FillHolesSOFA = 205,
    AnchorLayout_HeadDiameter = 2100,
    AnchorLayout_HeadThickness = 2099,
    AnchorLayout_Hnom = 2089,
    AnchorLayout_IsAnchorENFatigueCompliant = 1165,
    AnchorLayout_IsNutAndWasher = 447,
    AnchorLayout_IsReducedInstallTorque = 209,
    AnchorLayout_Layout = 200,
    AnchorLayout_LayoutCustom = 736,
    AnchorLayout_MasonryFastenerConfiguration = 874,
    AnchorLayout_MinConcreteCover = 451,
    AnchorLayout_MinRebarSize = 452,
    AnchorLayout_OffsetXFromAnchorPlate = 428,
    AnchorLayout_OffsetYFromAnchorPlate = 429,
    AnchorLayout_OnlyUKTAApprovals = 2263,
    AnchorLayout_Radius = 140,
    AnchorLayout_Rotate = 500,
    AnchorLayout_SeismicFillingSet = 798,
    AnchorLayout_ShowFullInternalPortfolio = 2259,
    AnchorLayout_Size = 211,
    AnchorLayout_SizeAC58 = 2256,
    AnchorLayout_Spacing_SX = 120,
    AnchorLayout_Spacing_SY = 130,
    AnchorLayout_TensileArea = 2001,
    AnchorLayout_Type = 210,
    AnchorLayout_TypeAC58 = 2255,
    AnchorLayout_ViewApproval = 519,
    AnchorLayout_ViewApproval_STO = 1058,
    AnchorLayout_ViewApproval_UKTA = 2257,
    AnchorLayout_ViewCSTBDocument = 3227,
    AnchorLayout_WasherDiameter = 448,
    AnchorLayout_WasherThickness = 449,
    AnchorPlate_AdjustThickness = 2003,
    AnchorPlate_AdvancedCalculation = 1019,
    AnchorPlate_AdvancedSettings = 1020,
    AnchorPlate_AnchorPlateSize = 749,
    AnchorPlate_CalculationType = 1018,
    AnchorPlate_CmdRotateAnticlockwise = 108,
    AnchorPlate_CmdRotateClockwise = 107,
    AnchorPlate_CustomGroutCompressiveStrength = 112,
    AnchorPlate_CustomGroutCompressiveStrength_Asad = 2130,
    AnchorPlate_CustomLayoutPoints = 115,
    AnchorPlate_CustomPlateMaterial = 1109,
    AnchorPlate_CustomStiffenerMaterial = 1110,
    AnchorPlate_Diameter = 104,
    AnchorPlate_DirectWeldGravity = 2070,
    AnchorPlate_DirectWeldLength = 2063,
    AnchorPlate_DirectWeldLocation = 2061,
    AnchorPlate_DirectWeldMaterial = 2060,
    AnchorPlate_DirectWeldMaterialBetaW = 2069,
    AnchorPlate_DirectWeldMaterialDensity = 2067,
    AnchorPlate_DirectWeldMaterialEModulus = 2066,
    AnchorPlate_DirectWeldMaterialPoisson = 2068,
    AnchorPlate_DirectWeldMaterialUltimateStrength = 2064,
    AnchorPlate_DirectWeldMaterialYieldStrength = 2065,
    AnchorPlate_DirectWeldThickness = 2071,
    AnchorPlate_Exists = 100,
    AnchorPlate_FlangeWeldGravity = 1164,
    AnchorPlate_FlangeWeldLegLength = 1086,
    AnchorPlate_FlangeWeldLocation = 1042,
    AnchorPlate_FlangeWeldMaterial = 1041,
    AnchorPlate_FlangeWeldMaterialBetaW = 1135,
    AnchorPlate_FlangeWeldMaterialDensity = 1133,
    AnchorPlate_FlangeWeldMaterialEModulus = 1132,
    AnchorPlate_FlangeWeldMaterialPoisson = 1134,
    AnchorPlate_FlangeWeldMaterialUltimateStrength = 1130,
    AnchorPlate_FlangeWeldMaterialYieldStrength = 1131,
    AnchorPlate_FlangeWeldThickness = 1040,
    AnchorPlate_Grout = 113,
    AnchorPlate_Grout_Asad = 2131,
    AnchorPlate_Height = 102,
    AnchorPlate_IsCompressiveStrengthOfMortarHigh = 512,
    AnchorPlate_IsPlateMaterialCustom = 1112,
    AnchorPlate_IsStiffenerMaterialCustom = 1113,
    AnchorPlate_LedgerAngleLength = 2086,
    AnchorPlate_LedgerAngleProfileCustomHeight = 2083,
    AnchorPlate_LedgerAngleProfileCustomThickness = 2085,
    AnchorPlate_LedgerAngleProfileCustomWidth = 2084,
    AnchorPlate_LedgerAngleProfileFamilyId = 2080,
    AnchorPlate_LedgerAngleProfileSizeId = 2081,
    AnchorPlate_LedgerAngleProfileSizeName = 2082,
    AnchorPlate_LedgerAngleShape = 2088,
    AnchorPlate_MaterialDensity = 1122,
    AnchorPlate_MaterialEModulus = 1121,
    AnchorPlate_MaterialPoisson = 1123,
    AnchorPlate_MaterialUltimateStrength = 1119,
    AnchorPlate_MaterialYieldStrength = 1120,
    AnchorPlate_NotOwnedLearnMore = 1043,
    AnchorPlate_NotOwnedLearnMoreInfo = 1044,
    AnchorPlate_OptimizedThicknessMessage = 813,
    AnchorPlate_PlateShape = 106,
    AnchorPlate_PlateShapeCustom = 735,
    AnchorPlate_Rotate = 510,
    AnchorPlate_ShowOptimizedAnchorPlateThickness = 757,
    AnchorPlate_ShowStressDistribution = 681,
    AnchorPlate_SideLength = 103,
    AnchorPlate_StandoffDistance = 110,
    AnchorPlate_StandoffDistance_Asad = 2128,
    AnchorPlate_StandoffNutAndWasherHeight = 2261,
    AnchorPlate_StandoffRestraintLevel = 111,
    AnchorPlate_StandoffRestraintLevel_Asad = 2129,
    AnchorPlate_StandoffType = 114,
    AnchorPlate_StandoffType_Asad = 2132,
    AnchorPlate_StiffenerCount = 1045,
    AnchorPlate_StiffenerHeight = 1028,
    AnchorPlate_StiffenerHorizontalEdgeLength = 1029,
    AnchorPlate_StiffenerLayout = 1046,
    AnchorPlate_StiffenerLayoutCustom = 2012,
    AnchorPlate_StiffenerMaterial = 1022,
    AnchorPlate_StiffenerMaterialDensity = 1139,
    AnchorPlate_StiffenerMaterialEModulus = 1138,
    AnchorPlate_StiffenerMaterialPoisson = 1140,
    AnchorPlate_StiffenerMaterialUltimateStrength = 1136,
    AnchorPlate_StiffenerMaterialYieldStrength = 1137,
    AnchorPlate_StiffenerRadialOffset = 1077,
    AnchorPlate_StiffenerShape = 1023,
    AnchorPlate_StiffenerThickness = 1024,
    AnchorPlate_StiffenerVerticalEdgeLength = 1030,
    AnchorPlate_StiffenerWeldGravity = 1162,
    AnchorPlate_StiffenerWeldLegLength = 1088,
    AnchorPlate_StiffenerWeldLocation = 1034,
    AnchorPlate_StiffenerWeldMaterial = 1032,
    AnchorPlate_StiffenerWeldMaterialBetaW = 1146,
    AnchorPlate_StiffenerWeldMaterialDensity = 1144,
    AnchorPlate_StiffenerWeldMaterialEModulus = 1143,
    AnchorPlate_StiffenerWeldMaterialPoisson = 1145,
    AnchorPlate_StiffenerWeldMaterialUltimateStrength = 1141,
    AnchorPlate_StiffenerWeldMaterialYieldStrength = 1142,
    AnchorPlate_StiffenerWeldThickness = 1033,
    AnchorPlate_StiffenerWidth = 1027,
    AnchorPlate_StressImage = 1054,
    AnchorPlate_Thickness = 109,
    AnchorPlate_UseBaseplate = 743,
    AnchorPlate_VerifyDiaphragmShear = 2150,
    AnchorPlate_WebWeldGravity = 1163,
    AnchorPlate_WebWeldLegLength = 1087,
    AnchorPlate_WebWeldLocation = 1038,
    AnchorPlate_WebWeldMaterial = 1037,
    AnchorPlate_WebWeldMaterialBetaW = 1129,
    AnchorPlate_WebWeldMaterialDensity = 1127,
    AnchorPlate_WebWeldMaterialEModulus = 1126,
    AnchorPlate_WebWeldMaterialPoisson = 1128,
    AnchorPlate_WebWeldMaterialUltimateStrength = 1124,
    AnchorPlate_WebWeldMaterialYieldStrength = 1125,
    AnchorPlate_WebWeldThickness = 1036,
    AnchorPlate_WeldingType = 1085,
    AnchorPlate_Width = 101,
    AnchorPlate_SteelType = 686,
    AnchorUserChosen = 3205,
    Application_Altitude = 720,
    Application_Application = 642,
    Application_BarsSpacing = 1096,
    Application_BasicWindVelocityId = 723,
    Application_BeamBottomSegment = 712,
    Application_BeamToBeamDistance = 640,
    Application_BeamType = 639,
    Application_BuildingCategory = 643,
    Application_BuildingDepth = 696,
    Application_BuildingHeight = 695,
    Application_BuildingWidth = 694,
    Application_BuildingZone = 697,
    Application_CalculatePostAndRailDeflection = 748,
    Application_CityAndStateId = 722,
    Application_Cladding = 692,
    Application_CladdingBarOffsetFromFloor = 1097,
    Application_CladdingBarOffsetFromRail = 1098,
    Application_CladdingBarsCount = 1095,
    Application_CladdingHeight = 764,
    Application_CladdingOffset = 763,
    Application_CladdingProfile = 1099,
    Application_CladdingType = 1094,
    Application_CladdingWidth = 765,
    Application_ConnectionType = 2058,
    Application_DistanceInsideTownTerrainId = 726,
    Application_DistanceToSeaside = 721,
    Application_DistanceUpwindToShorelineId = 725,
    Application_EnvironmentType = 637,
    Application_H = 714,
    Application_HandrailHeightOverGround = 709,
    Application_HandrailLength = 699,
    Application_IsFreeStandingWall = 698,
    Application_IsOrographyRelevant = 718,
    Application_Ld = 716,
    Application_Lu = 715,
    Application_NonCarryingLayer = 758,
    Application_ReferenceWindVelocityId = 727,
    Application_ReturnPeriod = 1062,
    Application_RoughnessClassId = 724,
    Application_TerrainCategoryId = 719,
    Application_TerrainType = 713,
    Application_Windloads = 690,
    Application_WindPressure = 1091,
    Application_WindUplift = 1092,
    Application_WindVelocityPressure = 728,
    Application_WindZone = 693,
    Application_X = 717,
    Application_ZipCodeHandrailLoadsId = 1105,
    BaseMaterial_ActiveBeam = 751,
    BaseMaterial_BalustradeHeight = 670,
    BaseMaterial_BalustradeWidth = 671,
    BaseMaterial_BeamLength = 665,
    BaseMaterial_BeamLeverArm = 666,
    BaseMaterial_BrickCharacteristic = 2153,
    BaseMaterial_BrickData = 638,
    BaseMaterial_BrickGroup = 529,
    BaseMaterial_BrickHeight = 611,
    BaseMaterial_BrickLayout = 530,
    BaseMaterial_BrickLayoutStartLeft = 619,
    BaseMaterial_BrickLength = 612,
    BaseMaterial_BrickSize = 613,
    BaseMaterial_BrickSizeId = 2152,
    BaseMaterial_BrickStrength = 533,
    BaseMaterial_BrickStrengthAC58 = 2254,
    BaseMaterial_BrickStrengthId = 532,
    BaseMaterial_BrickStrengthType = 531,
    BaseMaterial_BrickWidth = 610,
    BaseMaterial_CleaningMethod = 631,
    BaseMaterial_ConcreteCharacteristic = 1,
    BaseMaterial_ConcreteCharacteristic_Asad = 2110,
    BaseMaterial_ConcreteGrade = 2,
    BaseMaterial_ConcreteGrade_Asad = 2111,
    BaseMaterial_ConcreteGradeType = 85,
    BaseMaterial_ConcreteLengthNegative = 669,
    BaseMaterial_ConcreteLengthNegativeAnchor = 786,
    BaseMaterial_ConcreteLengthNegativeInfinity = 680,
    BaseMaterial_ConcreteLengthNegativeInfinityAnchor = 788,
    BaseMaterial_ConcreteLengthPositive = 668,
    BaseMaterial_ConcreteLengthPositiveAnchor = 785,
    BaseMaterial_ConcreteLengthPositiveInfinity = 679,
    BaseMaterial_ConcreteLengthPositiveInfinityAnchor = 787,
    BaseMaterial_ConcreteReinforcement = 14,
    BaseMaterial_ConcreteType = 3228,
    BaseMaterial_ConstrainedEdgeBottom = 618,
    BaseMaterial_ConstrainedEdgeLeft = 616,
    BaseMaterial_ConstrainedEdgeRight = 615,
    BaseMaterial_ConstrainedEdgeTop = 617,
    BaseMaterial_CubeStrength = 807,
    BaseMaterial_CubeStrength_Asad = 2168,
    BaseMaterial_CustomCompressiveStrength = 3,
    BaseMaterial_CustomDensity = 1101,
    BaseMaterial_CustomElasticity = 1103,
    BaseMaterial_CustomJointMaterialStrength = 2252,
    BaseMaterial_CustomPoisson = 1102,
    BaseMaterial_CustomShearOfSteel = 1104,
    BaseMaterial_CylinderStrength = 808,
    BaseMaterial_CylinderStrength_Asad = 2167,
    BaseMaterial_DeckThickness = 831,
    BaseMaterial_DrillingMethod = 12,
    BaseMaterial_DrillingMethod_Asad = 2126,
    BaseMaterial_EdgeReinforcement = 15,
    BaseMaterial_EdgeReinforcementHNA = 92,
    BaseMaterial_EdgeXNegative = 35,
    BaseMaterial_EdgeXNegative_Asad = 2114,
    BaseMaterial_EdgeXNegativeFromAnchor = 39,
    BaseMaterial_EdgeXPositive = 34,
    BaseMaterial_EdgeXPositive_Asad = 2113,
    BaseMaterial_EdgeXPositiveFromAnchor = 38,
    BaseMaterial_EdgeYNegative = 37,
    BaseMaterial_EdgeYNegative_Asad = 2116,
    BaseMaterial_EdgeYNegativeFromAnchor = 41,
    BaseMaterial_EdgeYPositive = 36,
    BaseMaterial_EdgeYPositive_Asad = 2115,
    BaseMaterial_EdgeYPositiveFromAnchor = 40,
    BaseMaterial_FasteningOption = 632,
    BaseMaterial_HeadJointCompletelyFilled = 2004,
    BaseMaterial_HeadJointSelection = 2264,
    BaseMaterial_HoleCondition = 446,
    BaseMaterial_HoleType = 13,
    BaseMaterial_HoleType_Asad = 2127,
    BaseMaterial_InstallationDirection = 1084,
    BaseMaterial_IsAnchorReinforcement = 86,
    BaseMaterial_IsAnchorReinforcementShear = 88,
    BaseMaterial_IsAnchorReinforcementTension = 87,
    BaseMaterial_IsCornerReinforcement = 89,
    BaseMaterial_IsEdgeXNegativeFromAnchorReinforced = 47,
    BaseMaterial_IsEdgeXNegativeLocked = 51,
    BaseMaterial_IsEdgeXNegativeReinforced = 43,
    BaseMaterial_IsEdgeXNegativeReinforced_Asad = 2118,
    BaseMaterial_IsEdgeXPositiveFromAnchorReinforced = 46,
    BaseMaterial_IsEdgeXPositiveLocked = 50,
    BaseMaterial_IsEdgeXPositiveReinforced = 42,
    BaseMaterial_IsEdgeXPositiveReinforced_Asad = 2117,
    BaseMaterial_IsEdgeYNegativeFromAnchorReinforced = 49,
    BaseMaterial_IsEdgeYNegativeLocked = 53,
    BaseMaterial_IsEdgeYNegativeReinforced = 45,
    BaseMaterial_IsEdgeYNegativeReinforced_Asad = 2120,
    BaseMaterial_IsEdgeYPositiveFromAnchorReinforced = 48,
    BaseMaterial_IsEdgeYPositiveLocked = 52,
    BaseMaterial_IsEdgeYPositiveReinforced = 44,
    BaseMaterial_IsEdgeYPositiveReinforced_Asad = 2119,
    BaseMaterial_IsLightweightConcrete = 453,
    BaseMaterial_IsLightweightConcrete_Asad = 2161,
    BaseMaterial_IsNonLoadBearingLayerPresent = 759,
    BaseMaterial_IsPlasteredWall = 605,
    BaseMaterial_IsSplittingReinforcement = 16,
    BaseMaterial_JointMaterial = 602,
    BaseMaterial_JointThicknessHorizontal = 601,
    BaseMaterial_JointThicknessVertical = 600,
    BaseMaterial_LightweightConcreteLambdaA = 454,
    BaseMaterial_LightweightConcreteLambdaA_Asad = 2162,
    BaseMaterial_MasonryAnchorPosition = 837,
    BaseMaterial_MasonryEdgeXNegative = 634,
    BaseMaterial_MasonryEdgeXNegativeFromAnchor = 738,
    BaseMaterial_MasonryEdgeXPositive = 633,
    BaseMaterial_MasonryEdgeXPositiveFromAnchor = 737,
    BaseMaterial_MasonryEdgeYNegative = 636,
    BaseMaterial_MasonryEdgeYNegativeFromAnchor = 740,
    BaseMaterial_MasonryEdgeYPositive = 635,
    BaseMaterial_MasonryEdgeYPositiveFromAnchor = 739,
    BaseMaterial_MasonryThickness = 872,
    BaseMaterial_MetalDeck = 869,
    BaseMaterial_MetalDeckAnchorOffset = 830,
    BaseMaterial_MetalDeckAnchorPosition = 826,
    BaseMaterial_MetalDeckFluteBaseWidth = 827,
    BaseMaterial_MetalDeckFluteDepth = 824,
    BaseMaterial_MetalDeckFluteDistance = 821,
    BaseMaterial_MetalDeckFlutePeakWidth = 828,
    BaseMaterial_MetalDeckLowerFluteWidth = 822,
    BaseMaterial_MetalDeckTotalFluteDepth = 829,
    BaseMaterial_MetalDeckType = 825,
    BaseMaterial_MetalDeckUpperFluteWidth = 823,
    BaseMaterial_NearestJointDistance = 873,
    BaseMaterial_NonLoadBearingLayer = 676,
    BaseMaterial_OffsetX = 674,
    BaseMaterial_OffsetY = 675,
    BaseMaterial_PlasterThickness = 606,
    BaseMaterial_PlasterYoungsModulus = 607,
    BaseMaterial_PrismStrength = 809,
    BaseMaterial_ReinforcementConditionShear = 90,
    BaseMaterial_ReinforcementConditionTension = 91,
    BaseMaterial_StairHeight = 672,
    BaseMaterial_StairLength = 673,
    BaseMaterial_Temperature = 835,
    BaseMaterial_TemperatureLongTerm = 11,
    BaseMaterial_TemperatureLongTerm_Asad = 2125,
    BaseMaterial_TemperatureShortTerm = 10,
    BaseMaterial_TemperatureShortTerm_Asad = 2124,
    BaseMaterial_Thickness = 33,
    BaseMaterial_Thickness_Asad = 2112,
    BaseMaterial_TorquingType = 877,
    BaseMaterial_UseCategory = 630,
    BaseMaterial_VerticalJointsFilled = 614,
    BaseMaterial_WHGApplication = 2148,
    BaseMaterial_WHGType = 2149,
    BaseMaterial_EdgeXNegativeFromAnchorPlateEdge = 848,
    BaseMaterial_EdgeXNegativeFromConcreteEdge = 844,
    BaseMaterial_EdgeXNegativeFromProfileCenter = 840,
    BaseMaterial_EdgeXPositiveFromAnchorPlateEdge = 847,
    BaseMaterial_EdgeXPositiveFromConcreteEdge = 843,
    BaseMaterial_EdgeXPositiveFromProfileCenter = 839,
    BaseMaterial_EdgeYNegativeFromAnchorPlateEdge = 850,
    BaseMaterial_EdgeYNegativeFromConcreteEdge = 846,
    BaseMaterial_EdgeYNegativeFromProfileCenter = 842,
    BaseMaterial_EdgeYPositiveFromAnchorPlateEdge = 849,
    BaseMaterial_EdgeYPositiveFromConcreteEdge = 845,
    BaseMaterial_EdgeYPositiveFromProfileCenter = 841,
    BaseplateThickness = 3203,
    ConstructionOptions_CorrosionResistant = 2098,
    ConstructionOptions_GalvanizedSteel = 2094,
    ConstructionOptions_MechanicallyGalvanized = 2096,
    ConstructionOptions_Sheradized = 2095,
    ConstructionOptions_StainlessSteel = 2097,
    Loads_AreFactoredSustainedLoadsActive = 1055,
    Loads_AreLoadCombinationsActive = 420,
    Loads_CmdETABSImport = 1072,
    Loads_CmdExport = 756,
    Loads_CmdImport = 755,
    Loads_CmdRobotImport = 1066,
    Loads_CmdSAP2000Import = 1063,
    Loads_CmdStaadProImport = 1080,
    Loads_Combi_psi_0_h = 818,
    Loads_Combi_psi_0_h_wind = 820,
    Loads_Combi_psi_0_v = 819,
    Loads_CompressiveStress = 604,
    Loads_ConcreteEtagEnOnly = 879,
    Loads_CustomizeDetails = 652,
    Loads_DeadLoad = 641,
    Loads_DeleteLoad = 2041,
    Loads_DesignMethodHNA = 477,
    Loads_DesignMethodHNA_Asad = 2155,
    Loads_DesignMethodSelection = 878,
    Loads_DlubalImportExport = 805,
    Loads_EmergencyExits = 760,
    Loads_ETABSImportExport = 1071,
    Loads_ExportRisa = 1061,
    Loads_FactoredSustainedLoadsFactor = 1056,
    Loads_FatigueLoadCycles = 2266,
    Loads_FatigueLoadType = 708,
    Loads_FireDuration = 416,
    Loads_FireExposure = 413,
    Loads_FlatBarResistance = 1100,
    Loads_ForceX = 400,
    Loads_ForceXVariable = 406,
    Loads_ForceY = 401,
    Loads_ForceYVariable = 407,
    Loads_ForceZ = 402,
    Loads_ForceZSustained = 1067,
    Loads_ForceZVariable = 408,
    Loads_FundamentalWindVelocity = 871,
    Loads_Gamma_perm_inf = 654,
    Loads_Gamma_perm_sup = 653,
    Loads_Gamma_var_h_bridge = 657,
    Loads_Gamma_var_h_building = 655,
    Loads_Gamma_var_h_wind = 659,
    Loads_Gamma_var_h_wind_bridge = 761,
    Loads_Gamma_var_v_bridge = 658,
    Loads_Gamma_var_v_building = 656,
    Loads_GammaZero = 2147,
    Loads_HandrailDesignMethodSelection = 1059,
    Loads_HandrailEtagEnOnly = 1060,
    Loads_HandrailMoreInfoOnSOFA = 2160,
    Loads_HandrailSafetyClass = 1167,
    Loads_HandrailStandOffDesignMethodSelection = 2157,
    Loads_ImportLoadCombinations = 518,
    Loads_InfillLoadInwards = 855,
    Loads_InfillLoadOutwards = 854,
    Loads_InfillPunctualLoadInwards = 1090,
    Loads_InfillPunctualLoadOutwards = 1089,
    Loads_InputHandrailLoads = 651,
    Loads_IsBridge = 834,
    Loads_IsFatigueExpertMode = 2005,
    Loads_LinearLoadInwards = 645,
    Loads_LinearLoadInwardsHeight = 647,
    Loads_LinearLoadOutwards = 644,
    Loads_LinearLoadOutwardsHeight = 646,
    Loads_LoadCombinationHNAWizard = 838,
    Loads_LoadCombinations = 513,
    Loads_LoadCombinationsCalculationType = 780,
    Loads_LoadsPositionZ = 2087,
    Loads_LoadType = 734,
    Loads_MomentX = 403,
    Loads_MomentXSustained = 1068,
    Loads_MomentXVariable = 409,
    Loads_MomentY = 404,
    Loads_MomentYSustained = 1069,
    Loads_MomentYVariable = 410,
    Loads_MomentZ = 405,
    Loads_MomentZVariable = 411,
    Loads_MoreInfoOnFatigue = 2265,
    Loads_MoreInfoOnSOFA = 2159,
    Loads_NewAndImportLoad = 2015,
    Loads_OnSiteTestsValues = 859,
    Loads_OnSiteTestsValues_N_Rk = 860,
    Loads_OnSiteTestsValues_V_Rk = 861,
    Loads_OnSiteTestType = 862,
    Loads_PhiNonductile = 466,
    Loads_PunctualLoadInwards = 649,
    Loads_PunctualLoadInwardsHeight = 753,
    Loads_PunctualLoadInWorstPosition = 857,
    Loads_PunctualLoadOutwards = 648,
    Loads_PunctualLoadOutwardsHeight = 752,
    Loads_PunctualLoadRailInwards = 876,
    Loads_PunctualLoadRailOutwards = 875,
    Loads_RobotImportExport = 1065,
    Loads_SafetyLevel = 2146,
    Loads_SAP2000ImportExport = 1064,
    Loads_SeismicCategory = 419,
    Loads_SeismicDesignType = 412,
    Loads_SeismicDisplacementsType = 511,
    Loads_SeismicDLSShear = 415,
    Loads_SeismicDLSTension = 414,
    Loads_SeismicIntensityFactor = 2144,
    Loads_SeismicLoadContribution = 417,
    Loads_SeismicShearType = 468,
    Loads_SeismicTensionType = 467,
    Loads_SelectedLoadCombination = 514,
    Loads_ShearLoadDistributionType = 2267,
    Loads_ShowAllLoads = 710,
    Loads_StaadProImportExport = 1079,
    Loads_StandOffDesignMethodSelection = 2156,
    Loads_StaticLoadType = 418,
    Loads_StaticShearType = 731,
    Loads_StaticTensionType = 730,
    Loads_StructuralMember = 2145,
    Loads_UseLoadCombinations_SE = 1166,
    Loads_UseOnSiteTestsData = 858,
    Loads_VerticalLoad = 650,
    Loads_VerticalPunctualLoad = 856,
    Loads_VerticalPunctualLoadInWorstPosition = 853,
    Optimize_AnchorLayout = 534,
    Optimize_AnchorOffset = 812,
    Optimize_AnchorPlatePosition = 521,
    Optimize_AnchorPlateSize = 520,
    Optimize_AxialSpacing = 522,
    Optimize_BaseMaterialThickness = 523,
    Optimize_BaseplateSize = 528,
    Optimize_EdgeDistance = 524,
    Optimize_FindSolution = 833,
    Optimize_PostDistance = 810,
    OptimizeOnEmbedmentDepth = 3204,
    Option_AnchorPlateFactor = 769,
    Option_ConcreteCapacityFactor = 1074,
    Option_ConcreteSafetyFactorGammaC = 2014,
    Option_CoordinateSystemCenter = 795,
    Option_CoordinateSystemCenterX = 796,
    Option_CoordinateSystemCenterY = 797,
    Option_CustomPictures = 2158,
    Option_DesignMethodGroupId = 526,
    Option_DesignName = 434,
    Option_DesignStandard = 444,
    Option_EdgeDistanceDisplayType = 781,
    Option_ETAGOnly = 432,
    Option_FaxNumber = 474,
    Option_HandrailDisplacementLimit = 832,
    Option_HandrailDisplacementLimitBridges = 1106,
    Option_INSteelSafetyFactorGammaM0 = 2008,
    Option_INSteelSafetyFactorGammaM1 = 2009,
    Option_INSteelSafetyFactorGammaMb = 2011,
    Option_INSteelSafetyFactorGammaMw = 2010,
    Option_MaterialSafetyFactor = 1049,
    Option_MeasureAnchorPlate = 3208,
    Option_MeasureBaseMaterialEdgeFrom = 3209,
    Option_MinimumAnchorToProfileDistance = 806,
    Option_MinimumConcreteCover = 2040,
    Option_NoteCombinedTensionAndShearLoad = 802,
    Option_NoteDisplacements = 803,
    Option_NoteInstallationData = 804,
    Option_NoteLoadCaseResultingAnchorForces = 799,
    Option_NoteShearLoad = 801,
    Option_NoteTensionLoad = 800,
    Option_OstInputsEnabled = 1051,
    Option_RegionId = 443,
    Option_ReportAddress = 472,
    Option_ReportCompanyName = 471,
    Option_ReportContactPerson = 470,
    Option_ReportEmail = 475,
    Option_ReportFasteningPoint = 469,
    Option_ReportFirstPage = 463,
    Option_ReportLanguageLcid = 462,
    Option_ReportLoadCombination = 608,
    Option_ReportNotes = 465,
    Option_ReportPaperSizeId = 460,
    Option_ReportPhoneNumber = 473,
    Option_ReportTemplateId = 464,
    Option_ReportTypeId = 461,
    Option_SafetyFactorForPermamentLoads = 770,
    Option_SafetyFactorForVariableLoads = 771,
    Option_StainlessSteel = 817,
    Option_SteelCapacityFactor = 1073,
    Option_SteelGuideline = 2007,
    Option_SteelSafetyFactorGammaM0 = 814,
    Option_SteelSafetyFactorGammaM1 = 815,
    Option_SteelSafetyFactorGammaM2 = 816,
    Option_SteelSafetyFactorGammaM5 = 870,
    Option_STOSteelSafetyFactorGammaC = 1083,
    Option_TrimbleConnectFolderId = 794,
    Option_TrimbleConnectLocation = 792,
    Option_TrimbleConnectReportName = 793,
    Option_TrimbleConnectUpload = 791,
    Option_UnitArea = 438,
    Option_UnitAreaPerLength = 2044,
    Option_UnitDensity = 1107,
    Option_UnitForce = 440,
    Option_UnitForcePerLength = 677,
    Option_UnitLength = 437,
    Option_UnitLengthLarge = 767,
    Option_UnitMoment = 441,
    Option_UnitMomentPerLength = 1078,
    Option_UnitStress = 439,
    Option_UnitStressSmall = 768,
    Option_UnitTemperature = 442,
    Option_WeldsCapacityFactor = 1070,
    Profile_Angle = 308,
    Profile_CmdEccentricityRotateAnticlockwise = 733,
    Profile_CmdEccentricityRotateClockwise = 732,
    Profile_CmdEccentricityRotatePostAnticlockwise = 777,
    Profile_CmdEccentricityRotatePostClockwise = 776,
    Profile_CustomFlangeThickness = 304,
    Profile_CustomFlangeThickness_Asad = 2165,
    Profile_CustomHeight = 302,
    Profile_CustomHeight_Asad = 2163,
    Profile_CustomProfileMaterial = 1108,
    Profile_CustomThickness = 307,
    Profile_CustomThickness_Asad = 2166,
    Profile_CustomWidth = 303,
    Profile_CustomWidth_Asad = 2164,
    Profile_CustomWidth2 = 1081,
    Profile_DoubleFlatBarCustomWidth = 3206,
    Profile_FlangeWeldThickness = 705,
    Profile_HandrailPostAngle = 775,
    Profile_HandrailPostProfileFamilyId = 746,
    Profile_HandrailRailAngle = 772,
    Profile_HandrailRailCmdEccentricityRotateAnticlockwise = 774,
    Profile_HandrailRailCmdEccentricityRotateClockwise = 773,
    Profile_HandrailRailProfileFamilyId = 742,
    Profile_HandrailRailProfileSizeId = 744,
    Profile_HandrailRailProfileSizeName = 745,
    Profile_HandrailRailProfileSteelType = 747,
    Profile_HandrailSafetyDesign = 754,
    Profile_HorizontalPostMaterialDensity = 1160,
    Profile_HorizontalPostMaterialEModulus = 1159,
    Profile_HorizontalPostMaterialPoisson = 1161,
    Profile_HorizontalPostMaterialUltimateStrength = 1157,
    Profile_HorizontalPostMaterialYieldStrength = 1158,
    Profile_IsProfileMaterialCustom = 1111,
    Profile_MaterialDensity = 1117,
    Profile_MaterialEModulus = 1116,
    Profile_MaterialPoisson = 1118,
    Profile_MaterialUltimateStrength = 1114,
    Profile_MaterialYieldStrength = 1115,
    Profile_OffsetX = 305,
    Profile_OffsetY = 306,
    Profile_PostMaterialDensity = 1155,
    Profile_PostMaterialEModulus = 1154,
    Profile_PostMaterialPoisson = 1156,
    Profile_PostMaterialUltimateStrength = 1152,
    Profile_PostMaterialYieldStrength = 1153,
    Profile_ProfileFamilyId = 300,
    Profile_ProfileFamilyId_Asad = 2121,
    Profile_ProfileSizeId = 301,
    Profile_ProfileSizeId_Asad = 2122,
    Profile_ProfileSizeName = 320,
    Profile_RailMaterialDensity = 1150,
    Profile_RailMaterialEModulus = 1149,
    Profile_RailMaterialPoisson = 1151,
    Profile_RailMaterialUltimateStrength = 1147,
    Profile_RailMaterialYieldStrength = 1148,
    Profile_ShearLugAngle = 3218,
    Profile_ShearLugCmdRotateAnticlockwise = 3220,
    Profile_ShearLugCmdRotateClockwise = 3219,
    Profile_ShearLugCustomDepth = 3215,
    Profile_ShearLugCustomLength = 3212,
    Profile_ShearLugCustomThickness = 3214,
    Profile_ShearLugCustomWidth = 3213,
    Profile_ShearLugFamilyId = 3211,
    Profile_ShearLugSizeId = 3216,
    Profile_ShearLugSizeName = 3217,
    Profile_ShowWeldDesign = 701,
    Profile_SteelType = 702,
    Profile_UseProfile = 741,
    Profile_UseShearLug = 3210,
    Profile_Utilization = 704,
    Profile_WebWeldLocation = 2136,
    Profile_WebWeldMaterial = 2137,
    Profile_WebWeldMaterialBetaW = 2143,
    Profile_WebWeldMaterialDensity = 2141,
    Profile_WebWeldMaterialEModulus = 2140,
    Profile_WebWeldMaterialPoisson = 2142,
    Profile_WebWeldMaterialUltimateStrength = 2138,
    Profile_WebWeldMaterialYieldStrength = 2139,
    Profile_WebWeldThickness = 706,
    Profile_HorizontalPostProfileConnection = 864,
    Profile_HorizontalPostProfileFamilyId = 868,
    Profile_HorizontalPostProfileSizeId = 865,
    Profile_HorizontalPostProfileSizeName = 867,
    Profile_HorizontalPostSteelTypeHandrail = 866,
    Profile_IsHorizontalPostProfilePresent = 863,
    Profile_SteelTypeHandrail = 750,
    Profile_UsePinConnection = 1093,
    ProjectDesignType = 445,
    ProjectName = 435,
    SmartAnchor_AddMoreUserSelectedAnchors = 3226,
    SmartAnchor_AnchorGroupsForAsad = 3207,
    SmartAnchor_Application = 2046,
    SmartAnchor_Category = 2045,
    SmartAnchor_CurrentlySelectedAnchor = 2050,
    SmartAnchor_Enabled = 2090,
    SmartAnchor_OptimizationOptions_AnchorPosition = 3199,
    SmartAnchor_OptimizationOptions_ProfilePosition = 3198,
    SmartAnchor_OptimizationOptions_UtilizationSlider = 3197,
    SmartAnchor_PlateHidden = 3182,
    SmartAnchor_SelectCategoryAndApplication = 3224,
    SmartAnchor_SelectPreferredAnchor = 3221,
    SmartAnchor_ShowMore = 2049,
    SmartAnchor_SuggestedAnchorFamily = 2048,
    SmartAnchor_SuggestedAnchorSize = 2047,
    SmartAnchor_Text_ConcreteReinforcement = 2092,
    SmartAnchor_Text_CorrisionMaterial = 9999,
    SmartAnchor_Text_InstallationCondition = 2091,
    SmartAnchor_Text_Temprature = 2093,
    SmartAnchor_UserSelectedAnchorGroupsForAsad = 3223,
    SmartAnchor_UserSelectedAnchorSizes = 3225,
    Solution_CmdSpecificationText = 430,
    Solution_SpecificationText = 431,
    Stiffener_CustomStiffener = 2013,
    SupplementaryReinforcement_ApplyTensionValuesToShear = 2052,
    SupplementaryReinforcement_CBottomEffective = 2042,
    SupplementaryReinforcement_CEdge = 2033,
    SupplementaryReinforcement_CEdgeEffective = 2035,
    SupplementaryReinforcement_CEdgeEffectiveLeft = 2056,
    SupplementaryReinforcement_CEdgeEffectiveRight = 2057,
    SupplementaryReinforcement_CmdRotateAnticlockwise = 2025,
    SupplementaryReinforcement_CmdRotateClockwise = 2024,
    SupplementaryReinforcement_ConcreteReinforcement = 2030,
    SupplementaryReinforcement_ConcreteReinforcement_Asad = 2133,
    SupplementaryReinforcement_CTop = 2034,
    SupplementaryReinforcement_CTopEffective = 2036,
    SupplementaryReinforcement_EdgeReinforcement = 2031,
    SupplementaryReinforcement_EdgeReinforcement_Asad = 2134,
    SupplementaryReinforcement_FirstRebarCenterOffset = 2039,
    SupplementaryReinforcement_Height = 2037,
    SupplementaryReinforcement_IsSplittingReinforcement = 2032,
    SupplementaryReinforcement_IsSplittingReinforcement_Asad = 2135,
    SupplementaryReinforcement_PositionTolerance = 2021,
    SupplementaryReinforcement_ReinforcementCategory = 2016,
    SupplementaryReinforcement_ReinforcementDiameter = 2018,
    SupplementaryReinforcement_ReinforcementSpacing = 2027,
    SupplementaryReinforcement_ReinforcementType = 2017,
    SupplementaryReinforcement_ReinforcementUtilization = 2028,
    SupplementaryReinforcement_Rotate = 2023,
    SupplementaryReinforcement_SurfaceNumber = 2020,
    SupplementaryReinforcement_SurfaceReinforcement = 2019,
    SupplementaryReinforcement_SurfaceUtilization = 2029,
    SupplementaryReinforcement_TensionLeftWidth = 2055,
    SupplementaryReinforcement_TensionReinforcementSpacing = 2053,
    SupplementaryReinforcement_TensionRightWidth = 2054,
    SupplementaryReinforcement_Width = 2038,
    SupplementaryReinforcement_YieldStrength = 2026,
    SupplementaryReinforcement_DirectionOfCasting = 2022,
    Update_PhasedOutFastenerFamily = 2043,
    Checkbot_IOM_BeamsDescription = 2943,
    SmartAnchor_EnableTco = 2169
}

export interface UIPropertyConfig {
    DefaultValue: number;
    Editable: boolean;
    ItemsTexts: {[    key: string]:  UIPropertyTexts};
    MaxValue: number;
    MinValue: number;
    Property: UIProperty;
    Texts: UIPropertyTexts;
    Value: any;
    Visible: boolean;
}

export interface UIPropertyTexts {
    displayKey: string;
    displayName: string;
    titleDisplayKey: string;
    tooltip: string;
    tooltipTitle: string;
}
export interface UIPropertyValue {
    Confirmed: boolean;
    GenerateReport: boolean;
    Property: UIProperty;
    RunAdvancedCalculation: boolean;
    Value: any;
}
export interface UIPropertyValueSelect extends UIPropertyValue {
    IgnoreVisibilityCheck: boolean;
}
