import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Design, DesignEvent, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    AppSettings, IAbpInfo, IDesignInfo, IFavoritesInfo, IIntegrationsInfo, IQuickStartApplication,
    ITrackingInfo,
    IUserSettingsInfo, UserSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    ApplicationSettingsDisplayType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { normalizeArray } from '@profis-engineering/pe-ui-common/helpers/array-helper';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { getModuleOrder } from '@profis-engineering/pe-ui-common/helpers/module-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    CantOpenDesignBecauseLockedByOtherUser, DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';

import { environment } from '../../environments/environmentPe';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import { Region } from '../../shared/entities/code-lists/region';
import { DesignPe } from '../../shared/entities/design-pe';
import {
    detailedDisplayDesignToProjectDesign, getCalculationTypeText, IDetailedDisplayDesign
} from '../../shared/entities/display-design';
import { createKBLink } from '../../shared/entities/KB-link';
import { IDesignListInfo } from '../../shared/entities/module-initial-data';
import { UserSettingsPe } from '../../shared/entities/user-settings';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    MeasureAnchorPlateMode, MeasureBaseMaterialEdgeFromMode
} from '../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    ConvertToLatestVersionDesignRequest, NewDesignDataEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    ProjectDesingFileStorageFormat
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    FeaturePe
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    ProjectDesignBaseEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignStandard as DesignStandardId, DesignType as DesignTypeId
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IConcreteFixingData, IHandrailFixingData, IMasonryFixingData, IMetalDeckFixing
} from '../../shared/helpers/app-settings-helper';
import { IBaseDesign as IBaseDesignPe } from '../../shared/services/calculation.common';
import { Constants } from '../entities/constants';
import { getSpriteAsIconStyle } from '../sprites';
import { AbpService } from './abp.service';
import { ApiService } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { DesignTemplateService } from './design-template.service';
import { DesignService } from './design.service';
import { DocumentService } from './document.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationProviderService {
    private designTypes?: { [id: string]: DesignType };

    constructor(
        private localization: LocalizationService,
        private codeList: CodeListService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private featuresVisibilityService: FeaturesVisibilityService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private appSettings: AppSettingsService,
        private calculationService: CalculationServicePE,
        private designService: DesignService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private abpService: AbpService,
        private favoritesService: FavoritesService,
        private ngZone: NgZone,
        private apiService: ApiService,
        private designTemplateService: DesignTemplateService,
        private trackingService: TrackingService
    ) { }

    public get getDesignTypes() {
        return this.codeList.projectCodeLists[ProjectCodeList.DesignType];
    }

    public get allowConcreteQuickStart() {
        if (this.userSettingsService.settings.quickStart.concrete.generalRegionId.value == null ||
            this.userSettingsService.settings.quickStart.concrete.generalRegionId.value == SpecialRegion.Default) {

            return this.allowConcrete;
        }

        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Concrete];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.quickStart.concrete.generalRegionId.value) ?? false;
    }

    public get allowConcrete() {
        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Concrete];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? false;
    }

    public get allowMasonryQuickStart() {
        if (this.userSettingsService.settings.quickStart.masonry.generalRegionId.value == null ||
            this.userSettingsService.settings.quickStart.masonry.generalRegionId.value == SpecialRegion.Default) {
            return this.allowMasonry;
        }

        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Masonry];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.quickStart.masonry.generalRegionId.value) ?? false;
    }

    public get allowMasonry() {
        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Masonry];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? false;
    }

    public get allowHandrailQuickStart() {
        if (this.userSettingsService.settings.quickStart.handrail.generalRegionId.value == null ||
            this.userSettingsService.settings.quickStart.handrail.generalRegionId.value == SpecialRegion.Default) {
            return this.allowHandrail;
        }

        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Handrail];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.quickStart.handrail.generalRegionId.value) ?? false;
    }

    public get allowHandrail() {
        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Handrail];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? false;
    }

    public get allowMetalDeckQuickStart() {
        if (this.userSettingsService.settings.quickStart.metalDeck.generalRegionId.value == null ||
            this.userSettingsService.settings.quickStart.metalDeck.generalRegionId.value == SpecialRegion.Default) {
            return this.allowMetalDeck;
        }

        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.MetalDeck];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.quickStart.metalDeck.generalRegionId.value) ?? false;
    }

    public get allowMetalDeck() {
        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.MetalDeck];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? false;
    }

    public get globalRegion() {
        return this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
    }

    public getAppSettings(): AppSettings[] {
        return [
            {
                order: getModuleOrder(Constants.ModuleIndex, 0),
                name: 'app-settings-concrete-module',
                collapsed: false,
                titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.QuickStart',
                tooltipKey: 'Agito.Hilti.Profis3.Main.Region.ShowHide',
                sections: [
                    {
                        id: 'application-settings-quick-start-concrete-config',
                        titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.QuickStartConcreteConfiguration',
                        type: ApplicationSettingsDisplayType.quickStartConcrete,
                        componentTagName: 'pe-app-settings-concrete-fixing',

                        loadSettings: () => this.appSettings.loadConcreteSettings(),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChangeConcrete(data as IConcreteFixingData, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateConcreteSettings(data as IConcreteFixingData)
                    },
                    {
                        id: 'application-settings-quick-start-handrail-config',
                        titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.QuickStartHandrailConfiguration',
                        type: ApplicationSettingsDisplayType.quickStartHandrail,
                        componentTagName: 'pe-app-settings-handrail-fixing',

                        loadSettings: () => this.appSettings.loadHandrailSettings(),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChangeHandrail(data as IHandrailFixingData, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateHandrailSettings(data as IHandrailFixingData)
                    },
                    {
                        id: 'application-settings-quick-start-masonry-config',
                        titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.QuickStartMasonryConfiguration',
                        type: ApplicationSettingsDisplayType.quickStartMasonry,
                        componentTagName: 'pe-app-settings-masonry-fixing',

                        loadSettings: () => this.appSettings.loadMasonrySettings(),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChangeMasonry(data as IMasonryFixingData, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateMasonrySettings(data as IMasonryFixingData)
                    },
                    {
                        id: 'application-settings-quick-start-metal-deck-config',
                        titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.QuickStartMetalDeckConfiguration',
                        type: ApplicationSettingsDisplayType.quickStartMetalDeck,
                        componentTagName: 'pe-app-settings-comd-fixing',

                        loadSettings: () => this.appSettings.loadMetalDeckSettings(),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChangeMetalDeck(data as IMetalDeckFixing, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateMetalDeckSettings(data as IMetalDeckFixing)
                    }
                ]
            }
        ];
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        this.designTypes = normalizeArray(this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[], 'id');
        const isNewHomePageEnabled = this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
        return [
            {
                // Concrete
                order: getModuleOrder(Constants.ModuleIndex, 0),
                showInQuickStart: true,
                showInCollapsedState: true,

                designType: DesignTypeId.Concrete,

                idQuickStart: 'quick-start-button-concrete',
                idCollapsed: 'quick-start-button-collapsed-concrete',
                imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-anchoring-to-concrete') : getSpriteAsIconStyle('sprite-concrete-fixing'),
                // actionTranslationKey is of no use in new home page - remove it when new home page deployment
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete.Action',
                designTypeTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Concrete.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete.DesignType',
                collapsedTextTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Concrete.Collapsed' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete.Collapsed',

                isEnabled: () => this.allowConcreteQuickStart,
                isDesignTypeDisabled: () => this.isDesignTypeDisabled(DesignTypeId.Concrete),
                getButtonTooltip: () => this.getQuickStartButtonTooltip(DesignTypeId.Concrete),
                createRegionDesignStandardApprovalNumber: () => {
                    const designSettings = this.userSettingsService.quickStartConcreteSettings;
                    if (designSettings == null)
                        return '';

                    return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandard);
                },
                getNewDesignName: () => this.designService.getNewDesignName(DesignTypeId.Concrete),
                createNewDesign: saveDesign => this.createNewQuickStartDesign(DesignTypeId.Concrete, saveDesign)
            },
            {
                // Masonry
                order: getModuleOrder(Constants.ModuleIndex, 1),
                showInQuickStart: true,
                showInCollapsedState: true,

                designType: DesignTypeId.Masonry,

                idQuickStart: 'quick-start-button-masonry',
                idCollapsed: 'quick-start-button-collapsed-masonry',
                imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-anchoring-to-masonry') : getSpriteAsIconStyle('sprite-masonry-fixing'),
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Masonry.Action',
                designTypeTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Masonry.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Masonry.DesignType',
                collapsedTextTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Masonry.Collapsed' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Masonry.Collapsed',
                kbTooltipTranslationKey: this.getKbTooltipTranslation(DesignTypeId.Masonry),

                isEnabled: () => this.allowMasonryQuickStart,
                isDesignTypeDisabled: () => this.isDesignTypeDisabled(DesignTypeId.Masonry),
                getButtonTooltip: () => this.getQuickStartButtonTooltip(DesignTypeId.Masonry),
                createRegionDesignStandardApprovalNumber: () => {
                    const designSettings = this.userSettingsService.quickStartMasonrySettings;
                    if (designSettings == null)
                        return '';

                    return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandard, undefined, DesignTypeId.Masonry);
                },
                isKbTooltipEnabled: () => this.isKbTooltipEnabled(DesignTypeId.Masonry),
                getKbLink: () => this.getKbLink(DesignTypeId.Masonry),
                getNewDesignName: () => this.designService.getNewDesignName(DesignTypeId.Masonry),
                createNewDesign: saveDesign => this.createNewQuickStartDesign(DesignTypeId.Masonry, saveDesign)
            },
            {
                // Handrail
                order: getModuleOrder(Constants.ModuleIndex, 2),
                showInQuickStart: true,
                showInCollapsedState: true,

                designType: DesignTypeId.Handrail,

                idQuickStart: 'quick-start-button-handrail',
                idCollapsed: 'quick-start-button-collapsed-handrail',
                imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-hand-rail') : getSpriteAsIconStyle('sprite-handrail-fixing'),
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Handrail.Action',
                designTypeTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Handrail.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Handrail.DesignType',
                collapsedTextTranslationKey: isNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.QuickStart.Handrail.Collapsed' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Handrail.Collapsed',

                isEnabled: () => this.allowHandrailQuickStart,
                isDesignTypeDisabled: () => this.isDesignTypeDisabled(DesignTypeId.Handrail),
                getButtonTooltip: () => this.getQuickStartButtonTooltip(DesignTypeId.Handrail),
                createRegionDesignStandardApprovalNumber: () => {
                    const designSettings = this.userSettingsService.quickStartHandrailSettings;
                    if (designSettings == null)
                        return '';

                    return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandard);
                },
                getNewDesignName: () => this.designService.getNewDesignName(DesignTypeId.Handrail),
                createNewDesign: saveDesign => this.createNewQuickStartDesign(DesignTypeId.Handrail, saveDesign)
            },
            {
                // MetalDeck
                order: getModuleOrder(Constants.ModuleIndex, 3),
                showInQuickStart: true,
                showInCollapsedState: true,
                designType: DesignTypeId.MetalDeck,
                idQuickStart: 'quick-start-button-metaldeck',
                idCollapsed: 'quick-start-button-collapsed-metaldeck',
                imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-concrete-over-metal-deck') : getSpriteAsIconStyle('sprite-comd-fixing'),
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.MetalDeck.Action',
                designTypeTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.MetalDeck.DesignType',
                collapsedTextTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.MetalDeck.Collapsed',
                kbTooltipTranslationKey: this.getKbTooltipTranslation(DesignTypeId.MetalDeck),

                isEnabled: () => this.allowMetalDeckQuickStart,
                isDesignTypeDisabled: () => this.isDesignTypeDisabled(DesignTypeId.MetalDeck),
                getButtonTooltip: () => this.getQuickStartButtonTooltip(DesignTypeId.MetalDeck),
                createRegionDesignStandardApprovalNumber: () => {
                    const designSettings = this.userSettingsService.quickStartMetalDeckSettings;
                    if (designSettings == null)
                        return '';

                    return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandard);
                },
                isKbTooltipEnabled: () => this.isKbTooltipEnabled(DesignTypeId.MetalDeck),
                getKbLink: () => this.getKbLink(DesignTypeId.MetalDeck),
                getNewDesignName: () => this.designService.getNewDesignName(DesignTypeId.MetalDeck),
                createNewDesign: saveDesign => this.createNewQuickStartDesign(DesignTypeId.MetalDeck, saveDesign)
            }
        ];
    }

    public getDesignListInfo(): IDesignListInfo[] {
        const common = {
            newDesignFromTemplate: (templateId: string, projectId?: string, designName?: string) => this.designService.newDesignFromTemplate(templateId, projectId, designName),
            openTemplate: (templateId: string) => this.designService.openTemplate(templateId),
            openTemplateSettings: (templateId: string, onDesignSettingsClosed?: () => void) => this.openTemplateSettings(templateId, onDesignSettingsClosed),
            openFromDocumentDesign: (documentDesign: IDesignListItem) => this.designService.openFromDocumentDesign(documentDesign),
            openDesignSettings: (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => this.openDesignSettings(designId, onDesignSettingsClosed),
            getDesign: async (designId: string) => {
                const documentDesign = this.documentService.findDesignById(designId);
                return (await this.calculationService.openFromDocumentDesign(documentDesign as unknown as IBaseDesignPe, true)).design;
            },
            getProjectDesignFromDesign: (design: Design) => (design as DesignPe).designData.projectDesign,
            getDesignFileBytes: (design: string) => this.designService.convertDesignToXml(design)
        };

        return [
            {
                ...common,
                // Concrete
                order: getModuleOrder(Constants.ModuleIndex, 0),
                designTypeId: DesignTypeId.Concrete,
                designTypeName: this.translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Concrete'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-concrete'),

                getCalculationTypeText: (calculationType: number, connectionType: number) => getCalculationTypeText(DesignTypeId.Concrete, calculationType, connectionType, this.localization),
                isEnabled: () => true,
                checkDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design as unknown as IBaseDesignPe, false));
                },
                downloadDesign: (name: string, designId: string) => this.designService.downloadDesign(DesignTypeId.Concrete, name, designId),
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-concrete', getDesignThumbnail),
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, 'sprite-model3d-concrete', getDesignThumbnail)
            },
            {
                ...common,
                // Masonry
                order: getModuleOrder(Constants.ModuleIndex, 1),
                designTypeId: DesignTypeId.Masonry,
                designTypeName: this.translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Masonry'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-masonry'),

                getCalculationTypeText: (calculationType: number, connectionType: number) => getCalculationTypeText(DesignTypeId.Masonry, calculationType, connectionType, this.localization),

                isEnabled: () => true,
                checkDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design as unknown as IBaseDesignPe, false));
                },
                downloadDesign: (name: string, designId: string) => this.designService.downloadDesign(DesignTypeId.Masonry, name, designId),
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-masonry', getDesignThumbnail),
                toDisplayDesignTemplate: (design: DesignTemplateEntity, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesignTemplate(design, 'sprite-model3d-masonry', getDesignThumbnail)
            },
            {
                ...common,
                // Handrail
                order: getModuleOrder(Constants.ModuleIndex, 2),
                designTypeId: DesignTypeId.Handrail,
                designTypeName: this.translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Handrail'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-handrail'),

                getCalculationTypeText: (calculationType: number, connectionType: number) => getCalculationTypeText(DesignTypeId.Handrail, calculationType, connectionType, this.localization),

                isEnabled: () => true,
                checkDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design as unknown as IBaseDesignPe, false));
                },
                downloadDesign: (name: string, designId: string) => this.designService.downloadDesign(DesignTypeId.Handrail, name, designId),
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-handrail', getDesignThumbnail),
                toDisplayDesignTemplate: (design: DesignTemplateEntity, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesignTemplate(design, 'sprite-model3d-handrail', getDesignThumbnail)
            },
            {
                ...common,
                // MetalDeck
                order: getModuleOrder(Constants.ModuleIndex, 3),
                designTypeId: DesignTypeId.MetalDeck,
                designTypeName: this.translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.MetalDeck'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-concrete'),

                getCalculationTypeText: (calculationType: number, connectionType: number) => getCalculationTypeText(DesignTypeId.MetalDeck, calculationType, connectionType, this.localization),

                isEnabled: () => true,
                checkDesign: (design: IBaseDesign) => {
                    return this.ngZone.run(() => this.calculationService.openFromDocumentDesign(design as unknown as IBaseDesignPe, false));
                },
                downloadDesign: (name: string, designId: string) => this.designService.downloadDesign(DesignTypeId.MetalDeck, name, designId),
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-concrete', getDesignThumbnail),
                toDisplayDesignTemplate: (design: DesignTemplateEntity, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesignTemplate(design, 'sprite-model3d-concrete', getDesignThumbnail)
            }
        ];
    }

    public getDesignInfo(designName?: string): IDesignInfo[] {
        return [
            {
                // Concrete
                order: getModuleOrder(Constants.ModuleIndex, 0),
                id: 'add-edit-design-image-container-concrete',
                designTypeId: DesignTypeId.Concrete,
                titleTranslationKey: 'Agito.Hilti.Profis3.AddEditDesign.Type.Concrete',
                mainTagName: 'pe-main',
                componentTagName: 'pe-add-edit-design',
                imageStyle: () => getSpriteAsIconStyle('sprite-anchor'),
                availableRegions: this.getAvailableDesignTypeRegions(DesignTypeId.Concrete),

                isEnabled: (regionId) => !this.isDesignTypeDisabledCustom(DesignTypeId.Concrete, regionId),
                isAvailable: () => true,
                getDesignName: () => designName ?? this.designService.getNewDesignName(DesignTypeId.Concrete),
                getNewDesignButtonTooltip: (regionId) => this.getNewDesignButtonTooltipCustom(DesignTypeId.Concrete, regionId)
            },
            {
                // Masonry
                order: getModuleOrder(Constants.ModuleIndex, 1),
                id: 'add-edit-design-image-container-masonry',
                designTypeId: DesignTypeId.Masonry,
                titleTranslationKey: 'Agito.Hilti.Profis3.AddEditDesign.Type.Masonry',
                mainTagName: 'pe-main',
                componentTagName: 'pe-add-edit-design',
                imageStyle: () => getSpriteAsIconStyle('sprite-masonry'),
                availableRegions: this.getAvailableDesignTypeRegions(DesignTypeId.Masonry),

                isEnabled: (regionId) => !this.isDesignTypeDisabledCustom(DesignTypeId.Masonry, regionId),
                isAvailable: () => true,
                getDesignName: () => designName ?? this.designService.getNewDesignName(DesignTypeId.Masonry),
                getNewDesignButtonTooltip: (regionId) => this.getNewDesignButtonTooltipCustom(DesignTypeId.Masonry, regionId)
            },
            {
                // Handrail
                order: getModuleOrder(Constants.ModuleIndex, 2),
                id: 'add-edit-design-image-container-handrail',
                designTypeId: DesignTypeId.Handrail,
                titleTranslationKey: 'Agito.Hilti.Profis3.AddEditDesign.Type.Handrail',
                mainTagName: 'pe-main',
                componentTagName: 'pe-add-edit-design',
                imageStyle: () => getSpriteAsIconStyle('sprite-stairs'),
                availableRegions: this.getAvailableDesignTypeRegions(DesignTypeId.Handrail),

                isEnabled: (regionId) => !this.isDesignTypeDisabledCustom(DesignTypeId.Handrail, regionId),
                isAvailable: () => true,
                getDesignName: () => designName ?? this.designService.getNewDesignName(DesignTypeId.Handrail),
                getNewDesignButtonTooltip: (regionId) => this.getNewDesignButtonTooltipCustom(DesignTypeId.Handrail, regionId)
            },
            {
                // MetalDeck
                order: getModuleOrder(Constants.ModuleIndex, 3),
                id: 'add-edit-design-image-container-metaldeck',
                designTypeId: DesignTypeId.MetalDeck,
                titleTranslationKey: 'Agito.Hilti.Profis3.AddEditDesign.Type.MetalDeck',
                mainTagName: 'pe-main',
                componentTagName: 'pe-add-edit-design',
                imageStyle: () => getSpriteAsIconStyle('sprite-comd'),
                availableRegions: this.getAvailableDesignTypeRegions(DesignTypeId.MetalDeck),

                isEnabled: (regionId) => !this.isDesignTypeDisabledCustom(DesignTypeId.MetalDeck, regionId),
                isAvailable: () => true,
                getDesignName: () => designName ?? this.designService.getNewDesignName(DesignTypeId.MetalDeck),
                getNewDesignButtonTooltip: (regionId) => this.getNewDesignButtonTooltipCustom(DesignTypeId.MetalDeck, regionId)
            }
        ];
    }

    public isDesignTypeDisabled(designTypeId: DesignTypeId) {
        switch (designTypeId) {
            case DesignTypeId.Concrete: {
                const quickStartConcreteSettings = this.userSettingsService.quickStartConcreteSettings;

                const concreteRegion = quickStartConcreteSettings.general_region?.id != null && quickStartConcreteSettings.general_region.id > 0
                    ? quickStartConcreteSettings.general_region.id
                    : this.globalRegion?.id;

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Concrete, concreteRegion);
            }

            case DesignTypeId.Handrail: {
                const quickStartHandrailSettings = this.userSettingsService.quickStartHandrailSettings;
                const handrailRegion = quickStartHandrailSettings.general_region?.id != null && quickStartHandrailSettings.general_region.id > 0
                    ? quickStartHandrailSettings.general_region.id
                    : this.globalRegion?.id;

                if (handrailRegion == KnownRegion.Australia) {
                    return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Handrail_Australia, KnownRegion.Australia);
                }

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Handrail, handrailRegion);
            }

            case DesignTypeId.Masonry: {
                const quickStartMasonrySettings = this.userSettingsService.quickStartMasonrySettings;
                const masonryRegion = quickStartMasonrySettings.general_region?.id != null && quickStartMasonrySettings.general_region.id > 0
                    ? quickStartMasonrySettings.general_region.id
                    : this.globalRegion?.id;

                if (quickStartMasonrySettings.calculation_designStandard != null &&
                    quickStartMasonrySettings.calculation_designStandard.id == DesignStandardId.ACI) {
                    return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry_ACI, masonryRegion);
                }

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry, masonryRegion);
            }

            case DesignTypeId.MetalDeck: {
                const quickStartMetalDeckSettings = this.userSettingsService.quickStartMetalDeckSettings;
                const metalDeckRegion = quickStartMetalDeckSettings.general_region?.id != null && quickStartMetalDeckSettings.general_region.id > 0
                    ? quickStartMetalDeckSettings.general_region.id
                    : this.globalRegion?.id;

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_MetalDeck, metalDeckRegion);
            }

            default:
                throw new Error('Unknown Design type.');
        }
    }

    public getQuickStartButtonTooltip(designTypeId: DesignTypeId): string {
        if (!this.isDesignTypeDisabled(designTypeId)) {
            return '';
        }

        switch (designTypeId) {
            case DesignTypeId.Concrete: {
                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Concrete);
            }

            case DesignTypeId.Handrail: {
                const quickStartHandrailSettings = this.userSettingsService.quickStartHandrailSettings;

                if ((quickStartHandrailSettings.general_region?.id != null && quickStartHandrailSettings.general_region.id > 0 && quickStartHandrailSettings.general_region.id == KnownRegion.Australia) ||
                    this.userSettingsService.settings.application.general.regionId.value == KnownRegion.Australia) {
                    return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Handrail_Australia);
                }

                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Handrail);
            }

            case DesignTypeId.Masonry: {
                const quickStartMasonrySettings = this.userSettingsService.quickStartMasonrySettings;
                if (quickStartMasonrySettings.calculation_designStandard.id == DesignStandardId.ACI) {
                    return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Masonry_ACI);
                }
                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Masonry);
            }

            case DesignTypeId.MetalDeck: {
                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_MetalDeck);
            }

            default:
                throw new Error('Unknown Design type.');
        }
    }

    public getAbpInfo(): IAbpInfo | undefined {
        return {
            isAdvancedCalculationPossible: (designType, designStandard, region) => this.abpService.isAdvancedCalculationPossibleById(designType, designStandard, region),
            isRigidityCheckPossible: (designStandard, region) => this.abpService.isRigidityCheckPossibleById(designStandard, region)
        };
    }

    public getTrackingInfo(): ITrackingInfo[] {
        const common: Partial<ITrackingInfo> = {
            saveTrackingData: (
                design: Design,
                appVersion?: string,
                useDevFeatures?: boolean,
                isNewHomePage?: boolean
            ) => {
                const designPe = design as DesignPe;

                return this.trackingService.saveTrackingActData(
                    designPe.designData.projectDesign,
                    designPe.usageCounter.toUsageCounter(),
                    designPe.projectOpenType,
                    design.id,
                    designPe.createdFromTemplate,
                    designPe.templateId,
                    designPe.designLocation,
                    appVersion ?? '',
                    useDevFeatures ?? false,
                    isNewHomePage ?? false,
                    designPe.designType
                );
            },

            trackOnTemplateClose: (
                design: Design,
                designId: string,
                appVersion?: string,
                useDevFeatures?: boolean,
                isNewHomePage?: boolean
            ) => {
                const designPe = design as DesignPe;

                return this.trackingService.trackOnTemplateClose(
                    designPe.designData.projectDesign,
                    designPe.usageCounter.toUsageCounter(),
                    designPe.projectOpenType,
                    designId,
                    designPe.createdFromTemplate,
                    designPe.templateId,
                    designPe.designLocation,
                    appVersion ?? '',
                    useDevFeatures ?? false,
                    isNewHomePage ?? false
                );
            },

            trackOnDesignOpen: (
                design: Design,
                appVersion?: string
            ) => {
                const designPe = design as DesignPe;

                return this.trackingService.trackOnDesignOpen(
                    designPe.designType,
                    designPe.id,
                    appVersion ?? ''
                );
            },
            trackOnDesignClose: (
                design: Design,
                appVersion?: string,
                useDevFeatures?: boolean,
                isNewHomePage?: boolean
            ) => {
                const designPe = design as DesignPe;

                return this.trackingService.trackOnDesignClose(
                    designPe.designData.projectDesign,
                    designPe.usageCounter.toUsageCounter(),
                    designPe.projectOpenType,
                    design.id,
                    designPe.createdFromTemplate,
                    designPe.templateId,
                    appVersion ?? '',
                    useDevFeatures ?? false,
                    isNewHomePage ?? false,
                    designPe.designData.reportData.BaseplateDesignData,
                    designPe.designData.reportData.BaseplateDesignTiming,
                    designPe.designLocation,
                );
            },

            handleDesignBrowserUnload: (
                sessionKey: string,
                design: Design,
                appVersion?: string,
                useDevFeatures?: boolean,
                isNewHomePage?: boolean
            ) => {
                const designPe = design as DesignPe;

                return this.trackingService.handleDesignBrowserUnload(
                    sessionKey,
                    designPe.projectDesign,
                    designPe.usageCounter,
                    designPe.projectOpenType,
                    design.id,
                    designPe.createdFromTemplate,
                    designPe.templateId,
                    designPe.designLocation,
                    appVersion ?? '',
                    useDevFeatures ?? false,
                    isNewHomePage ?? false
                );
            },
        };

        return [
            {
                ...common,
                // Concrete
                designTypeId: DesignTypeId.Concrete
            },
            {
                ...common,
                // Handrail
                designTypeId: DesignTypeId.Handrail
            },
            {
                ...common,
                // Masonry
                designTypeId: DesignTypeId.Masonry
            },
            {
                ...common,
                // MetalDeck
                designTypeId: DesignTypeId.MetalDeck
            }
        ];
    }

    public getIntegrationsInfo(quickStartApplications: IQuickStartApplication[]): IIntegrationsInfo | undefined {
        return {
            getQuickStartApplicationForIntegration: (integrationType: DocumentIntegrationType) => {
                switch (integrationType) {
                    case DocumentIntegrationType.Risa:
                    case DocumentIntegrationType.Ram:
                        return this.getQuickStartApplicationForIntegrationRisaRam(quickStartApplications.find(x => x.designType == DesignTypeId.Concrete));

                    default:
                        return undefined;
                }
            }
        };
    }

    public getFavoritesInfo(): IFavoritesInfo {
        return {
            getFavoritesType: (menuType: MenuType, designType: number) => this.favoritesService.getFavoritesTypePe(menuType, designType),
            getMenuRegionIdFromFavorites: (id: string, designType: number) => this.favoritesService.getMenuRegionIdFromFavorites(id, designType)
        };
    }


    public getKbTooltipTranslation(designTypeId: DesignTypeId) {
        if (designTypeId == null) {
            return undefined;
        }

        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[]).find(d => d.id == designTypeId);
        return designType?.kbTooltip;
    }

    public isKbTooltipEnabled(designTypeId: DesignTypeId) {
        if (designTypeId == DesignTypeId.Masonry) {
            return this.userSettingsService.settings.quickStart.masonry.calculationDesignStandardId.value == DesignStandardId.ACI;
        }
        else if (designTypeId == DesignTypeId.MetalDeck) {
            return this.userSettingsService.settings.quickStart.metalDeck.calculationDesignStandardId.value == DesignStandardId.ACI;
        }

        return false;
    }

    public getKbLink(designTypeId: DesignTypeId) {
        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[]).find(d => d.id == designTypeId);

        const selectedRegion = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        const kbUrlTemplate = selectedRegion?.profis3KBUrlAci;

        if (
            selectedRegion?.id == null
            || designType?.kbNumberRegion?.[selectedRegion?.id] == null
            || kbUrlTemplate == null
        ) {
            return undefined;
        }

        return createKBLink(kbUrlTemplate, designType.kbNumberRegion[selectedRegion.id]);
    }

    public getUserSettingsInfo(): IUserSettingsInfo {
        const settings = new UserSettingsPe(false);

        return {
            userSettingsKey: 'pe',
            userSettings: {} as unknown as UserSettingsSection,
            userSettingsRoot: {
                profileSizeFavorites: settings.profileSizeFavorites,
                steelTypeFavorites: settings.steelTypeFavorites,
                anchorFavorites: settings.anchorFavorites,

                concretevirtualtourSeen: settings.concretevirtualtourSeen,
                handrailvirtualtourSeen: settings.handrailvirtualtourSeen,
                smartanchorvirtualtourSeen: settings.smartanchorvirtualtourSeen,
                searchSolutionsCount: settings.searchSolutionsCount,
                smartdesignvirtualtourseen: settings.smartdesignvirtualtourseen,

                generateReportFollowUpActionsConcreteHidden: settings.generateReportFollowUpActionsConcreteHidden,
            } as unknown as UserSettingsSection,
            quickStart: settings.quickStart as unknown as UserSettingsSection,
            applicationImportLoads: settings.applicationImportLoads as unknown as UserSettingsSection,
            applicationModelDisplayOptions: settings.applicationModelDisplayOptions as unknown as UserSettingsSection
        };
    }

    private async openTemplateSettings(templateId: string, onClosed?: () => void) {
        const template = await this.designTemplateService.getById(templateId);
        const projectDesign = (await this.convertStringToLatestDesign(template.ProjectDesign)).body as ProjectDesignBaseEntity;
        const design = await this.calculationService.createAndOpenTemplate(projectDesign, template.DesignTemplateDocumentId, template.DesignTemplateName);
        const openSettings = () => {
            const designInfo = this.getDesignInfo().find(x => x.designTypeId == design.designType.id && x.isAvailable(design.region.id));
            if (designInfo == undefined) {
                throw new Error('Design info not found');
            }

            this.modalService.openAddEditDesignFromModule({
                design: {
                    id: design.id,
                    name: design.designName,
                    projectId: design.projectId,
                    projectName: design.projectName,
                    region: design.region,
                    designType: design.designType.id,
                    displayDesignType: DisplayDesignType.template,
                    anchorName: template.AnchorName,
                    approvalNumber: template.ApprovalNumber,
                    designTemplateDocumentId: template.DesignTemplateDocumentId,
                    projectDesign: projectDesign,
                    design: design
                },
                addEditType: AddEditType.edit,
                selectedModuleDesignInfo: designInfo,
                onDesignEdited: (design) => {
                    const templateProjectDesign = detailedDisplayDesignToProjectDesign(projectDesign, design as IDetailedDisplayDesign);
                    return this.designTemplateService.update({
                        designTemplateDocumentId: template.DesignTemplateDocumentId,
                        designTypeId: templateProjectDesign.ProjectDesignType,
                        designStandardId: templateProjectDesign.Options.DesignStandard,
                        regionId: templateProjectDesign.Options.RegionId,
                        templateName: (design as IDetailedDisplayDesign).name,
                        anchorName: template.AnchorName,
                        approvalNumber: template.ApprovalNumber,
                        projectDesign: JSON.stringify(templateProjectDesign)
                    });
                }
            }).closed
                .finally(() => {
                    // template has no publish so we don't need to call result.design.publish();
                    if (onClosed != undefined) {
                        onClosed();
                    }
                });
        };

        if (!design.confirmChangeInProgress) {
            openSettings();
        }
        else {
            design.on(DesignEvent.designChangesConfirmed, () => openSettings());
        }
    }


    private openDesignSettings(designId: string, onClosed?: () => void) {
        const documentDesign = this.documentService.findDesignById(designId);
        const promiseCatch = (response: any) => {
            if (response instanceof CantOpenDesignBecauseLockedByOtherUser) {
                this.modalService.openAlertWarning(
                    this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(
                        this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'),
                        {
                            user: response.username ?? ''
                        }
                    )
                );
            }
            throw response;
        };

        return this.documentService.openDesign(documentDesign)
            .then((projectDesign) => {
                this.calculationService.openFromProjectDesign(projectDesign, documentDesign.id)
                    .then((result) => {
                        if (!result.design.confirmChangeInProgress) {
                            this.callPromise(documentDesign, result.projectDesign as ProjectDesignBaseEntity, result.design, onClosed);
                        }
                        else {
                            result.design.on(DesignEvent.designChangesConfirmed, () => this.callPromise(documentDesign, result.projectDesign as ProjectDesignBaseEntity, result.design, onClosed));
                        }
                    }).catch((response) => { promiseCatch(response); });
            }).catch((response) => { promiseCatch(response); });
    }

    private callPromise(documentDesign: IDesignListItem, projectDesign: ProjectDesignBaseEntity, design: DesignPe, onClosed?: () => void) {
        const designInfo = this.getDesignInfo().find(x => x.designTypeId == documentDesign.metaData.designType && x.isAvailable(documentDesign.metaData.region));
        if (designInfo == undefined) {
            throw new Error('Design info not found');
        }

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: documentDesign.id,
                name: documentDesign.designName,
                projectId: documentDesign.projectId,
                projectName: documentDesign.projectName,
                region: this.getRegionById(documentDesign.metaData.region),
                designType: documentDesign.metaData.designType,
                displayDesignType: design.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                anchorName: documentDesign.metaData.productName,
                approvalNumber: documentDesign.metaData.approvalNumber,
                designTemplateDocumentId: documentDesign.id,
                projectDesign: projectDesign,
                design: design
            },
            addEditType: AddEditType.edit,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: (design) => {
                const detailedDesign = design as IDetailedDisplayDesign;
                if (detailedDesign.displayDesignType != DisplayDesignType.template) {
                    return this.documentService.updateDesignWithNewContent(detailedDesign.id, detailedDesign.projectId, detailedDesign.name, projectDesign, documentDesign.metaData, detailedDesign, true, true, DocumentAccessMode.Update);
                }

                return this.designTemplateService.update({
                    designTemplateDocumentId: documentDesign.id,
                    designTypeId: projectDesign.ProjectDesignType,
                    designStandardId: projectDesign.Options.DesignStandard,
                    regionId: projectDesign.Options.RegionId,
                    templateName: detailedDesign.name,
                    anchorName: documentDesign.metaData.productName,
                    approvalNumber: documentDesign.metaData.approvalNumber,
                    projectDesign: JSON.stringify(projectDesign)
                });
            }
        }).closed.finally(() => {
            if (onClosed) {
                onClosed();
            }
        });
    }

    private getRegionById(regionId: number | null) {
        const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private translate(key: string) {
        return this.localization.getString(key);
    }

    private getAvailableDesignTypeRegions(designTypeId: DesignTypeId) {
        if (this.designTypes == null) {
            return [];
        }

        const designTypeItem = this.designTypes[designTypeId];
        if (designTypeItem?.allowedDesignStandardRegions == null) {
            return [];
        }

        return designTypeItem.allowedDesignStandardRegions.map(r => r.RegionId);
    }

    private isDesignTypeDisabledCustom(designTypeId: DesignTypeId, regionId: number) {
        if (this.designTypes == null) {
            return true;
        }

        const designTypeItem = this.designTypes[designTypeId];
        if (designTypeItem?.allowedDesignStandardRegions == null) {
            return true;
        }

        const isDesignTypeAllowed = designTypeItem.allowedDesignStandardRegions.some((row) => row.RegionId == regionId);
        const allowedStandards = designTypeItem.allowedDesignStandardRegions.find(row => row.RegionId == regionId);
        return !isDesignTypeAllowed
            || this.isDesignTypeFeatureDisabled(designTypeId, regionId)
            ||
            (
                allowedStandards != null
                && allowedStandards.DesignStandards.length == 1
                && this.isDesignStandardDisabled(designTypeItem.id, regionId, allowedStandards.DesignStandards[0])
            );
    }

    // Design standard
    private isDesignStandardDisabled(designType: number, regionId: number, designStandard: number) {
        switch (designType) {
            case DesignTypeId.Concrete:
            case DesignTypeId.Handrail:
            case DesignTypeId.MetalDeck:
                return false;

            case DesignTypeId.Masonry:
                if (designStandard == DesignStandardId.ACI) {
                    return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry_ACI, regionId);
                }
                return false;

            default:
                throw new Error('Unknown Design type.');
        }
    }

    private isDesignTypeFeatureDisabled(designTypeId: DesignTypeId, regionId?: number) {
        switch (designTypeId) {
            case DesignTypeId.Concrete:
                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Concrete, regionId);

            case DesignTypeId.Handrail:
                if (regionId == KnownRegion.Australia) {
                    return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Handrail_Australia, regionId);
                }

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Handrail, regionId);

            case DesignTypeId.Masonry:
                if (this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry_ACI, regionId)) {
                    return true;
                }

                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Masonry, regionId);

            case DesignTypeId.MetalDeck:
                return this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_MetalDeck, regionId);

            default:
                throw new Error('Unknown Design type.');
        }
    }

    private getNewDesignButtonTooltipCustom(designTypeId: DesignTypeId, regionId: number) {
        if (!this.isDesignTypeFeatureDisabled(designTypeId, regionId)) {
            return undefined;
        }

        return this.getNewDesignButtonTooltipInternal(designTypeId, regionId);
    }

    private getNewDesignButtonTooltipInternal(designTypeId: DesignTypeId, regionId?: number) {
        switch (designTypeId) {
            case DesignTypeId.Concrete: {
                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Concrete);
            }

            case DesignTypeId.Handrail: {
                if (regionId == KnownRegion.Australia) {
                    return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Handrail_Australia);
                }

                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Handrail);
            }

            case DesignTypeId.Masonry: {
                const tooltip = this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Masonry_ACI);
                if (tooltip != null && tooltip.length > 0) {
                    return tooltip;
                }

                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_Masonry);
            }

            case DesignTypeId.MetalDeck: {
                return this.featuresVisibilityInfo.tooltip(FeaturePe.Application_DesignType_MetalDeck);
            }

            default:
                throw new Error('Unknown Design type.');
        }
    }


    private async createNewQuickStartDesign(designType: DesignTypeId, saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        const quickStartSettings = this.userSettingsService.getQuickStartSettings(designType);

        const regionId = quickStartSettings.general_region != null && quickStartSettings.general_region.id > 0
            ? quickStartSettings.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value as number;

        const dataEntity: NewDesignDataEntity = {
            DesignType: designType,
            RegionId: regionId,
            DesignStandard: quickStartSettings.calculation_designStandard?.id,
            DesignMethodGroup: quickStartSettings.calculation_designMethodGroup?.id as number,
            EtaData: quickStartSettings.calculation_etaData,
            EtagEnOnly: quickStartSettings.calculation_etagEnOnly,
            OstInputsData: quickStartSettings.calculation_ostInputsData,
            Language: null as unknown as string,
            NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
            NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
            ProjectName: saveDesign?.projectName,
            ProjectId: saveDesign?.projectId,
            DesignName: saveDesign?.designName,
            UnitLength: quickStartSettings.units_length?.id as number,
            UnitLengthLarge: quickStartSettings.units_length_large?.id as number,
            UnitArea: quickStartSettings.units_area?.id as number,
            UnitStress: quickStartSettings.units_stress?.id as number,
            UnitForce: quickStartSettings.units_force?.id as number,
            UnitMoment: quickStartSettings.units_moment?.id as number,
            UnitTemperature: quickStartSettings.units_temperature?.id as number,
            UnitForcePerLength: quickStartSettings.units_force_per_length?.id as number,
            UnitMomentPerLength: quickStartSettings.units_moment_per_length?.id as number,
            UnitStressSmall: quickStartSettings.units_stress_small?.id as number,
            UnitDensity: quickStartSettings.units_density?.id as number,
            UserSettingsDefaults: this.userSettingsService.readServerSideSettingsEntity(),
            AnchorPlateFactor: quickStartSettings.anchor_plate_factor as number,
            SafetyFactorForPermamentLoads: quickStartSettings.permenent_loads as number,
            SafetyFactorForVariableLoads: quickStartSettings.variable_loads as number,
            EdgeDistanceDisplayType: quickStartSettings.edgeDistanceDisplayType?.id as number,
            MinimumAnchorToProfileDistance: quickStartSettings.minimum_anchor_to_profile_distance as number,
            ConcreteSafetyFactorGammaC: quickStartSettings.concreteSafetyFactorGammaC as number,
            SteelSafetyFactorGammaM0: quickStartSettings.steelSafetyFactorGammaM0 as number,
            SteelSafetyFactorGammaM1: quickStartSettings.steelSafetyFactorGammaM1 as number,
            SteelSafetyFactorGammaM2: quickStartSettings.steelSafetyFactorGammaM2 as number,
            SteelSafetyFactorGammaM5: quickStartSettings.steelSafetyFactorGammaM5 as number,
            HandrailDisplacementLimit: quickStartSettings.handrailDisplacementLimit as number,
            CustomPictures: null as unknown as string[],
            AdvancedBaseplateCalculation: this.userSettingsService.getAdvancedBaseplateCalculation(regionId),
            UseDevFeatures: this.sharedEnvironmentService.data?.useDevFeatures ?? false,
            MaterialSafetyFactor: quickStartSettings.materialSafetyFactor as number,
            IntegrationDocument: saveDesign.integrationDocument as DocumentModel,
            WeldsCapacityFactor: quickStartSettings.weldsCapacityFactor as number,
            SteelCapacityFactor: quickStartSettings.steelCapacityFactor as number,
            ConcreteCapacityFactor: quickStartSettings.concreteCapacityFactor as number,
            HandrailDisplacementLimitBridges: quickStartSettings.handrailDisplacementLimitBridges as number,
            INSteelSafetyFactorGammaM0: quickStartSettings.inSteelSafetyFactorGammaM0 as number,
            INSteelSafetyFactorGammaM1: quickStartSettings.inSteelSafetyFactorGammaM1 as number,
            INSteelSafetyFactorGammaMw: quickStartSettings.inSteelSafetyFactorGammaMw as number,
            INSteelSafetyFactorGammaMb: quickStartSettings.inSteelSafetyFactorGammaMb as number,
            SteelGuideLine: quickStartSettings.steelGuideLine as number,
            UIVersion: this.sharedEnvironmentService.data?.applicationVersion as string,
            CalculationMethodState: this.userSettingsService.settings.application.advancedCalculation.calculationMethodState.value as number,
            MinimumConcreteCover: quickStartSettings.minimumConcreteCover as number,
            CustomerOriginId: this.userService.authentication.customerOriginId as string,
            CountryCode: this.userService.authentication.country as string,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? false,
            MeasureAnchorPlate: MeasureAnchorPlateMode.UsingOverallWidthAndHeight,
            MeasureBaseMaterialEdgeFrom: MeasureBaseMaterialEdgeFromMode.ProfileCenter,
            CreatedWithCheckbot: false
        };

        // create design
        const design = await this.calculationService.createAndOpen(dataEntity);
        if (saveDesign.integrationDocument != null) {
            design.integrationDocument = saveDesign.integrationDocument;

            if (saveDesign.integrationDocument.IntegrationType == DocumentIntegrationType.Ram) {
                const project = this.documentService.findProjectById(saveDesign.projectId);
                design.loadCombinations = [];
                this.userService.changeDesign(project, design);

                await this.modalService.openImportRamLoadCombinations().closed;

                this.userService.design.usageCounter.RamImport++;
            }
        }

        return {
            designId: design.id,
            path: UrlPath.main,
            design,
            success: true
        };
    }

    private getQuickStartApplicationForIntegrationRisaRam(concreteQuickStartApplication: IQuickStartApplication | undefined): IQuickStartApplication | undefined {
        if (concreteQuickStartApplication == null) {
            return undefined;
        }

        const concreteRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.quickStart.concrete.generalRegionId.value as number)?.id
            ?? this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number)?.id;
        if (this.featuresVisibilityInfo.isDisabled(FeaturePe.Application_DesignType_Concrete, concreteRegion)) {
            return undefined;
        }

        return concreteQuickStartApplication;
    }

    private convertStringToLatestDesign(projectDesignAsString: string) {
        const urlConvert = `${environment.baseplateApplicationWebServiceUrl}ConvertToLatestVersionDesign`;

        const dataConvert: ConvertToLatestVersionDesignRequest = {
            RawProjectDesign: projectDesignAsString,
            Language: this.localization.selectedLanguage,
            NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
            NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
            StorageFormat: ProjectDesingFileStorageFormat.JSON
        };

        return this.apiService.request<ProjectDesignBaseEntity>(new HttpRequest('POST', urlConvert, dataConvert));
    }
}
