import cloneDeep from 'lodash-es/cloneDeep';
import { Subject } from 'rxjs';

import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Design, DesignEvent, IBaseDesign, ICalculateAllDetailed, ICommonUIProperties,
    IDesignBaseConstructor, IDesignDeps as IDesignDepsBase, IDesignStateBase,
    IGlobalMetaPropertiesBase, ISnapshotBase, IUnitProviderObject
} from '@profis-engineering/pe-ui-common/entities/design';
import {
    Unit as UnitPe
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    PaperSize, ReportType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    CalculationServiceBase, ICalculateInternalOptionsBase
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import {
    Change, ChangesServiceBase as ChangesService
} from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { DesignExternalMetaData } from '@profis-engineering/pe-ui-common/services/document.common';
import {
    GuidServiceBase as GuidService
} from '@profis-engineering/pe-ui-common/services/guid.common';
import {
    LocalizationServiceBase as LocalizationService
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    LoggerServiceBase as LoggerService
} from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    UserServiceBase as UserService
} from '@profis-engineering/pe-ui-common/services/user.common';

import {
    HandrailApplicationType as HandrailApplicationTypeEntity
} from '../entities/code-lists/handrail-application-type';
import { ProjectCodeList } from '../enums/project-code-list';
import { ProgressStatus } from '../generated-modules/Hilti.PE.Core.Common';
import {
    CalculationResultEntity as ICalculationResultEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    DesignReportDataClientEntity as IDesignReportDataClientEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    BaseplateDesignDataEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    CalculationStatus
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    ProfileFormingNorme
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import {
    BaseplateSize, BrickSize, LoadCombination, LoadCombinationHNAWizard, UIProperty
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignLocationType, ProjectOpenType
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    CustomStiffenerEntity, ProjectDesignBaseEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AdvancedCalculationType, AnchorFilterType, ConcreteCharacteristic, ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC, ConcreteType, DesignMethodGroup as DesignMethodGroupEnum, DesignMethodHNA,
    DesignStandard as DesignStandardEnum, DesignType as DesignTypeId, FatigueLoadType,
    HandrailApplicationType, HandrailConnectionType, LoadCombinationsCalculationType, LoadType,
    PlateShape, SeismicShearType, SeismicTensionType, StaticLoadType, SteelGuideline,
    StiffenerLocation, StiffenerShape as StiffenerShapeId,
    SupplementaryReinforcementCategory as SupplementaryReinforcementCategoryEnum, WeldLocation,
    WHGType as WHGTypeEnum
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportRequest, GenerateReportResponse
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { DesignMethodGroupHelper } from '../helpers/design-method-group-helper';
import { getAllowedSteelGuidelines } from '../helpers/steel-guideline-helper';
import { fromPeUnit, toPeUnit } from '../helpers/unit-helper';
import { PropertyMetaData } from '../properties/properties';
import {
    CalculationServiceBasePE as CalculationServicePE, ICalculationResult
} from '../services/calculation.common';
import { CodeListServiceBase as CodeListService } from '../services/code-list.service.base';
import { AnchorFamily } from './code-lists/anchor-family';
import { AnchorSize as AnchorSizeEntity } from './code-lists/anchor-size';
import { AnchorType as AnchorTypeEntity } from './code-lists/anchor-type';
import { BrickGroup } from './code-lists/brick-group';
import { BrickLayout } from './code-lists/brick-layout';
import { BuildingArea } from './code-lists/building-area';
import { BuildingCategory } from './code-lists/building-category';
import { CodeList } from './code-lists/code-list';
import {
    ConcreteCharacteristic as ConcreteCharacteristicEntity
} from './code-lists/concrete-characteristic';
import { ConcreteGrade } from './code-lists/concrete-grade';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { DesignType } from './code-lists/design-type';
import { ProfileFamily } from './code-lists/profile-family';
import { ProfileSize } from './code-lists/profile-size';
import { Region } from './code-lists/region';
import { StandOff } from './code-lists/stand-off';
import { StiffenerLayout } from './code-lists/stiffener-layout';
import { WindLoadCity } from './code-lists/wind-load-city';
import { WindZone } from './code-lists/wind-zone';
import { DesignCodeList } from './design-code-list';
import { TrackingUsageCounterPe } from './tracking-usage-counter';

export interface IResetRotation {
    anchor?: boolean;
    plate?: boolean;
}

export enum CorrosionMaterial {
    Unknown = 0,
    Galvanized = 'G',
    Sheradized = 'D',
    Stainless = 'S',
    HighCorrosionResistant = 'H',
    MechanicallyGalvanized = 'M'
}

export enum StandOffType {
    Unknown = 0,
    None = 1,
    WithoutClamping = 2,
    WithClamping = 3,
    WithGrouting = 4,
    WithPlaster = 5
}

export interface IGlobalMetaProperties extends IGlobalMetaPropertiesBase {
    productCodeListProperty: DesignCodeList;
}

export interface ICalculateAllAnchor {
    id: number;
    name: string;
    detailed: ICalculateAllDetailed[];
}

export interface ICalculateAllAnchorDetailed extends ICalculateAllDetailed {
    size: string;
    diameter: number;
    embedmentDepth: number;
    isAnchorPlateSizeOk: boolean;
    isAnchorPlatePositionOk: boolean;
    isEdgeDistanceOk: boolean;
    isAxialSpacingOk: boolean;
    isBaseMaterialThicknessOk: boolean;
    calculationStatus: CalculationStatus;
    t: number;
    s: number;
    c: number;
    holeDiameter: number;
    fixtureThicknesMin: number;
    fixtureThicknesMax: number;
    allowedInAnchorFilters: AnchorFilterType[];
}

export interface ICalculateInternalOptions extends ICalculateInternalOptionsBase {
    generateReportData?: GenerateReportRequest;
}

export interface IGlobalMetaProperties extends IGlobalMetaPropertiesBase {
    productCodeListProperty: DesignCodeList;
}

export interface ISnapshot extends ISnapshotBase {
    states: IDesignState[];
}

export interface ICodeLists {
    [codeList: number]: CodeList[];
}

export interface ReportData extends IDesignReportDataClientEntity {
    LoadCombinationCount: number;
    LoadCombinationsEnabled: boolean;
    BaseplateDesignData?: BaseplateDesignDataEntity;
}

export interface IDesignPeConstructor extends IDesignBaseConstructor {
    projectDesign?: ProjectDesignBaseEntity;
    reportData?: ReportData;
}

export interface IBaseDesignPe extends IBaseDesign {
    integrationDocument?: DocumentModel;
}

export interface IDesignState extends IDesignStateBase {
    projectDesign: ProjectDesignBaseEntity;
    reportData: IDesignReportDataClientEntity;
    calculateAllData: ICalculateAllAnchor[];
    pendingCalculationResult: ICalculationResultEntity;
}

export interface ICalculateDesignRequestOptions {
    generateReportData?: GenerateReportRequest;
    calculateAll: boolean;
    importingLoadCases?: boolean;
    calculateAdvancedBaseplate?: boolean;
    changes?: Change[];
    calculateLongRunning?: boolean;
}

// ASAD
export interface AsadPoint {
    x: number;
    y: number;
}

export interface AsadData {
    input: AsadInput;
    output?: AsadOutput;
}

export interface AsadInput {
    generic: string;
    projectDesign: ProjectDesignBaseEntity;
    includeLogs: boolean;
    includeTimeEstimation: boolean;
    includeScValidation: boolean;
    timeEstimationRefresh: number;
    includeRandomErrors: boolean;
    includeFasteners: AsadIncludeFasteners;
    useLambda: boolean;
}

export const enum AsadIncludeFasteners {
    jsonOrSelected,
    ui,
    all
}

export interface AsadTimeEstimationUpdate {
    remainingCalculationTime: number;
    progress: number;
}

export interface AsadTimeEstimationCalculation {
    estimatedCalculationCount: number;
    progress: number;
}

export interface AsadTimeEstimationAverage {
    estimatedCalculationCount: number;
    progress: number;
}

export interface AsadOutput {
    maxOptimizeCasesCount: number;
    totalCalculationCount: number;
    calculationCount: number;
    runningOptimizationsCount: number;
    logs: string;
    optimizeMessages: AsadOptimizeMessage[];
    scopeCheckErrors: AsadCalculateScopeCheckError[];
    optimizeCases: Record<number, AsadOptimizeCase>;
    timeEstimationUpdate: AsadTimeEstimationUpdate;
    timeEstimationCalculation: AsadTimeEstimationCalculation;
    timeEstimationAverage: AsadTimeEstimationAverage;
    timeEstimations: AsadTimeEstimation[];
    optimizationDate: number;
    totalTime: number;
    doneCasesCount: number;
    totalCasesCount: number;
    feasibleCasesCount: number;
    errorCasesCount: number;
}

export interface AsadOptimizeMessage {
    id: number;
    severity: number;
    message: string;
}

export interface AsadTimeEstimation {
    timestamp: string;
    receiveTimestamp: number;
    remaining: number;
    passed: number;
    serversAvailable: number;
    estimatedCalculationCount: number;
}

export interface AsadOptimizeCase {
    optimizeCaseId: string;
    calculationCount: number;
    logs: string;
    optimizeCaseOutput?: AsadOptimizeCaseOutput;
    optimizeCaseDetails?: AsadOptimizeCaseDetails;
}

export interface AsadOptimizeCaseOutput {
    embedmentDepth: number;
    anchorCoordinates: AsadPoint[];
    anchorPlateWidth: number;
    anchorPlateHeight: number;
    area: number;
    exitFlag: number;
    constraintEvaluationsCount: number;
    profileEccentricityX: number;
    profileEccentricityY: number;
    utilizations: Record<string, number>;
    fillHoles: boolean;
    calculationTime: number;
    maxUtilization: number;
    isEdgeXNegativeReinforced: boolean;
    isEdgeXPositiveReinforced: boolean;
    isEdgeYNegativeReinforced: boolean;
    isEdgeYPositiveReinforced: boolean;
    baseMaterialEdgeXNegative?: number;
    baseMaterialEdgeXPositive?: number;
    baseMaterialEdgeYNegative?: number;
    baseMaterialEdgeYPositive?: number;
    searchAlgorithm: AsadSearchAlgorithm;
    TCO: number;
    BaseplateCosts: number;
    AnchorsCosts: number;
    InstallationCosts: number;
}

export const enum AsadSearchAlgorithm {
    DirectSearch,
    IPOPT
}

export interface AsadCalculateScopeCheckError {
    fastenerId: number;
    layoutNumber: number;
    numberOfAnchors: number;
    calculationCount: number;
    scopeChecks: string[];
    anchorCoordinates: AsadPoint[];
    anchorPlateWidth: number;
    anchorPlateHeight: number;
    profileEccentricityX: number;
    profileEccentricityY: number;
    embedmentDepth: number;
    fillHoles: boolean;
    isEdgeXNegativeReinforced: boolean;
    isEdgeXPositiveReinforced: boolean;
    isEdgeYNegativeReinforced: boolean;
    isEdgeYPositiveReinforced: boolean;
    baseMaterialEdgeXNegative: number;
    baseMaterialEdgeXPositive: number;
    baseMaterialEdgeYNegative: number;
    baseMaterialEdgeYPositive: number;
}

export interface AsadOptimizeCaseDetails {
    optimizeCaseIndex: number;
    fastenerId: number;
    layoutNumber: number;
    numberOfAnchors: number;
    fastenerFamilyId: number;
    isAtToolAllowed: boolean;
    isAutoCleaningAllowed: boolean;
    engineeringValue: number;
    partialFamilyId: number;
    variableEmbedmentDepth: boolean;
    corrosionMaterial: CorrosionMaterial;
}
// ASAD

export interface IDesignPeDeps extends IDesignDepsBase {
    codeList: CodeListService;
    calculation: CalculationServicePE;
}

export function getDesignPeDeps(
    logger: LoggerService,
    guid: GuidService,
    changes: ChangesService,
    user: UserService<unknown>,
    localization: LocalizationService,
    commonCodeList: CommonCodeListServiceBase,
    codeList: CodeListService,
    calculation: CalculationServicePE
) {
    return {
        logger,
        guid,
        changes,
        user,
        localization,
        commonCodeList,
        codeList,
        calculation
    } as IDesignPeDeps;
}

export class DesignDataPe {
    // Input
    public projectDesign!: ProjectDesignBaseEntity;
    public designCodeLists!: ICodeLists;
    public anchorsMarkedAsNew?: number[];

    // Results
    public reportData!: ReportData;
    public calculateAllData?: ICalculateAllAnchor[];
    public asadData: AsadData = { input: {} as any };
    public pendingCalculationResult?: ICalculationResultEntity;
}

export class DesignPe extends Design<DesignDataPe, IDesignState> implements IBaseDesignPe, IUnitProviderObject {
    // minimum anchor to profile distance is used in SC_GEOM_ANCHORS_PROFILE_DIST scope check
    public static readonly minimumAnchorToProfileDistanceInch = 2;

    public projectOpenType = ProjectOpenType.Blank;
    public designLocation = DesignLocationType.Unknown;
    public currentState: IDesignState;

    public calculateDefer: Deferred<ICalculationResult>;
    public snapshotStateId?: string = undefined;

    public usageCounter: TrackingUsageCounterPe;
    public progressAdvancedBaseplateCalculationSubject = new Subject<ProgressStatus>();
    public progressAdvancedBaseplateCalculation = this.progressAdvancedBaseplateCalculationSubject.asObservable();

    public savedProfileSizeFilter?: ProfileFormingNorme;
    public isAnchorAISolutionSelected = false;
    public isAnchorAISolutionSelectedOnce = false;

    // private tracking: TrackingService;
    private codeList: CodeListService;
    private calculationService: CalculationServicePE;

    public static get commonUiProperties(): ICommonUIProperties {
        return {
            regionPropertyId: UIProperty.Option_RegionId,
            designTypePropertyId: UIProperty.ProjectDesignType,
            designStandardPropertyId: UIProperty.Option_DesignStandard
        };
    }

    constructor(designDeps: IDesignPeDeps, design?: IDesignPeConstructor) {
        super(designDeps, DesignPe.commonUiProperties, design);

        this.codeList = designDeps.codeList;
        this.commonCodeList = designDeps.commonCodeList;
        this.calculationService = designDeps.calculation;

        this.stateChanged = this.stateChanged.bind(this);

        this.states = [];
        this.currentState = {} as IDesignState;
        this.savedStateIdx = 0;

        this.onStateChanged(this.stateChanged);

        this.calculateDefer = new Deferred<ICalculationResult>();

        this.designData = {
            designCodeLists: {},
            asadData: { input: {} as any },
            projectDesign: {} as any,
            reportData: {} as any
        };

        this.usageCounter = new TrackingUsageCounterPe(this.logger);

        this.integrationDocument = undefined;

        if (design != null) {
            this.designData.projectDesign = design.projectDesign as any;
            this.designName = design.designName ?? '';
            this.projectName = design.projectName ?? '';
        }
    }

    public get projectDesign() {
        return this.designData.projectDesign;
    }

    public get metaData() {
        return {} as DesignExternalMetaData;
    }

    public get canUndo() {
        return this.states.findIndex((state) => state === this.currentState) > 0;
    }
    public get canRedo() {
        const index = this.states.findIndex((state) => state === this.currentState);

        return index >= 0 && index < this.states.length - 1;
    }

    public get currentStateIdx() {
        return this.states.findIndex((state) => state === this.currentState);
    }

    public get isStateChanged() {
        return this.savedStateIdx != this.currentStateIdx;
    }

    public get globalMetaProperties(): IGlobalMetaProperties {
        return DesignPe.getAnchorMetaProperties(this.designMethodGroup?.id);
    }

    public get baseplateDesignData() {
        return this.designData.reportData.BaseplateDesignData;
    }

    public get useULSStresses() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_UseULSStresses.id];
    }
    public set useULSStresses(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_UseULSStresses.id] = value;
    }

    public get showPostAndRailDeflection() {
        return this.model[PropertyMetaData.Application_CalculatePostAndRailDeflection.id] as number;
    }

    public get showOptimizedAnchorPlateThickness() {
        return this.model[PropertyMetaData.AnchorPlate_ShowOptimizedAnchorPlateThickness.id] as boolean;
    }

    public get showStressDistribution() {
        return this.model[PropertyMetaData.AnchorPlate_ShowStressDistribution.id] as boolean;
    }

    public get showWeldDesign() {
        return this.model[PropertyMetaData.Profile_ShowWeldDesign.id] as boolean;
    }

    public get smartAnchorCategory() {
        return this.model[PropertyMetaData.SmartAnchor_Category.id] as number;
    }

    public get smartAnchorApplication() {
        return this.model[PropertyMetaData.SmartAnchor_Application.id] as number;
    }

    public get smartAnchorSuggestedAnchorSelected() {
        return this.model[PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id] as number;
    }

    public get smartAnchorUserSelected() {
        return this.model[PropertyMetaData.AnchorLayout_AnchorFamily.id] as number;
    }

    public get smartAnchorUserChosenVisible() {
        return this.properties.get(PropertyMetaData.AnchorUserChosen.id).hidden;
    }

    public get smartAnchorHiltiRecommendedAnchors() {
        return this.model[PropertyMetaData.SmartAnchor_AnchorGroupsForAsad.id] as number[];
    }

    public get isCBFEMCalculation() {
        return this.designData.reportData?.IsCBFEMCalculation ?? false;
    }

    public get isHandrailCBFEMCalculation() {
        return this.designData.reportData?.IsHandrailCBFEMCalculation ?? false;
    }

    public get useTallProfile() {
        return this.designData.reportData?.UseTallProfile ?? false;
    }

    public get loadType() {
        return this.model[PropertyMetaData.Loads_LoadType.id] as LoadType;
    }
    public set loadType(value) {
        this.model[PropertyMetaData.Loads_LoadType.id] = value;
    }

    public get selectedLoadCombinationId() {
        return this.model[PropertyMetaData.Loads_SelectedLoadCombination.id] as string;
    }
    public set selectedLoadCombinationId(value) {
        this.model[PropertyMetaData.Loads_SelectedLoadCombination.id] = value;
    }

    public get seismicTensionType() {
        return this.model[PropertyMetaData.Loads_SeismicTensionType.id] as SeismicTensionType;
    }
    public set seismicTensionType(value) {
        this.model[PropertyMetaData.Loads_SeismicTensionType.id] = value;
    }

    public get seismicShearType() {
        return this.model[PropertyMetaData.Loads_SeismicShearType.id] as SeismicShearType;
    }
    public set seismicShearType(value) {
        this.model[PropertyMetaData.Loads_SeismicShearType.id] = value;
    }

    public get drillingMethod() {
        return this.model[PropertyMetaData.BaseMaterial_DrillingMethod.id];
    }
    public set drillingMethod(value) {
        this.model[PropertyMetaData.BaseMaterial_DrillingMethod.id] = value;
    }

    public get isVariableLoadsPresent() {
        return this.loadType == LoadType.Fatigue ||
            this.loadType == LoadType.Static && this.staticLoadTypeId == StaticLoadType.Characteristic;
    }

    public get isLoadCombinationActive() {
        return this.model[PropertyMetaData.Loads_AreLoadCombinationsActive.id] as boolean;
    }
    public set isLoadCombinationActive(value) {
        this.model[PropertyMetaData.Loads_AreLoadCombinationsActive.id] = value;
    }

    public get isFactoredSustainedLoadsActive() {
        return this.model[PropertyMetaData.Loads_AreFactoredSustainedLoadsActive.id] as boolean;
    }

    public get isFatigueDesign() {
        return this.model[PropertyMetaData.Loads_LoadType.id] == LoadType.Fatigue;
    }

    public get isFireDesign() {
        return this.model[PropertyMetaData.Loads_LoadType.id] == LoadType.Fire;
    }

    public get staticLoadTypeId() {
        return this.model[PropertyMetaData.Loads_StaticLoadType.id] as StaticLoadType;
    }

    public get loadCombinations() {
        return this.model[PropertyMetaData.Loads_LoadCombinations.id] as LoadCombination[];
    }
    public set loadCombinations(value) {
        this.model[PropertyMetaData.Loads_LoadCombinations.id] = value;
    }

    public get loadCombinationHNAWizard() {
        return this.model[PropertyMetaData.Loads_LoadCombinationHNAWizard.id] as LoadCombinationHNAWizard;
    }

    public get designMethodHNA() {
        return this.model[PropertyMetaData.Loads_DesignMethodHNA.id] as DesignMethodHNA;
    }

    public get calculationType() {
        return this.model[PropertyMetaData.AnchorPlate_CalculationType.id] as AdvancedCalculationType;
    }
    public set calculationType(value) {
        this.model[PropertyMetaData.AnchorPlate_CalculationType.id] = value;
    }

    public get isSustainedLoadsPresent() {
        const isFactoredSustainedLoadsActivePresent = () => !this.properties.get(PropertyMetaData.Loads_AreFactoredSustainedLoadsActive.id).hidden;

        const isSustainedLoadsValuePresent = () => !this.properties.get(PropertyMetaData.Loads_ForceZSustained.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentXSustained.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentYSustained.id).hidden;

        const isVariableLoadsValuePresent = () => !this.isVariableLoadsPresent &&
            (!this.properties.get(PropertyMetaData.Loads_ForceZVariable.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentXVariable.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentYVariable.id).hidden);

        const isSustainedLoadCombinationsPresent = () => this.isLoadCombinationActive && this.loadCombinations.some(l => l.HasSustainedLoads);

        return isFactoredSustainedLoadsActivePresent() ||
            isSustainedLoadsValuePresent() ||
            isVariableLoadsValuePresent() ||
            isSustainedLoadCombinationsPresent();
    }

    public get fatigueLoadType() {
        return this.model[PropertyMetaData.Loads_FatigueLoadType.id] as FatigueLoadType;
    }

    public get alternatingLoads() {
        return this.designType.id == DesignTypeId.Concrete &&
            this.loadType == LoadType.Fatigue &&
            this.fatigueLoadType == FatigueLoadType.Alternating;
    }

    public get designMethodName() {
        if (this.designData == null) {
            return undefined;
        }

        return this.designData.reportData?.DesignMethodName;
    }

    public get approvalNumber() {
        if (this.designData == null) {
            return undefined;
        }

        return this.designData.reportData?.Approval.Number;
    }

    public get ApprovalNumberSTO() {
        if (this.designData == null) {
            return undefined;
        }

        return this.designData.reportData?.ApprovalSTO.Number;
    }

    public get ApprovalNumberUKTA() {
        if (this.designData == null) {
            return undefined;
        }

        return this.designData.reportData?.ApprovalUKTA.Number;
    }

    public get handrailApplication() {
        const codeList = this.designData.designCodeLists[DesignCodeList.HandrailApplication] as unknown as HandrailApplicationTypeEntity[];
        if (codeList == null) {
            return undefined;
        }

        return codeList.find((item) => item.id == (this.model[PropertyMetaData.Application_Application.id] as number));
    }

    public get handrailPostProfileFamilyId() {
        return this.model[PropertyMetaData.Profile_HandrailPostProfileFamilyId.id];
    }

    public get steelGuideline() {
        return this.model[PropertyMetaData.Option_SteelGuideline.id] as SteelGuideline;
    }

    public get steelMethod() {
        if (this.region?.id != null) {
            const steelGuideline = getAllowedSteelGuidelines(this.codeList.projectCodeLists, this.designStandard.id, this.region.id)
                .find((sg) => sg.id == this.steelGuideline);
            if (steelGuideline != null) {
                return this.localization.getString(steelGuideline.steelMethodTranslationKey ?? '');
            }
        }

        throw new Error(`Unknown steel guideline '${this.steelGuideline}'!`);
    }

    public get designStandard() {
        return this.getDesignStandardInternal();
    }
    public set designStandard(value) {
        this.model[PropertyMetaData.Option_DesignStandard.id] = value != null ? value.id : null;
    }

    public get designType() {
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[];
        const designTypeId = this.model[PropertyMetaData.ProjectDesignType.id] as number;
        const designtype = codeList.find((designType) => designType.id == designTypeId);

        if (designtype == null) {
            throw new Error(`Unknown design standard '${designTypeId}'!`);
        }

        return designtype;
    }

    public get designName() {
        return this.model[PropertyMetaData.Option_DesignName.id] as string;
    }
    public set designName(value) {
        this.model[PropertyMetaData.Option_DesignName.id] = value;
    }

    public get projectName() {
        return this.model[PropertyMetaData.ProjectName.id] as string;
    }
    public set projectName(value) {
        this.model[PropertyMetaData.ProjectName.id] = value;
    }

    public get unitLength() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitLength.id] as UnitPe);
    }
    public set unitLength(value) {
        this.model[PropertyMetaData.Option_UnitLength.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitLengthLarge() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitLengthLarge.id] as UnitPe);
    }
    public set unitLengthLarge(value) {
        this.model[PropertyMetaData.Option_UnitLengthLarge.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitArea() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitArea.id] as UnitPe);
    }
    public set unitArea(value) {
        this.model[PropertyMetaData.Option_UnitArea.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitStress() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitStress.id] as UnitPe);
    }
    public set unitStress(value) {
        this.model[PropertyMetaData.Option_UnitStress.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitStressSmall() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitStressSmall.id] as UnitPe);
    }
    public set unitStressSmall(value) {
        this.model[PropertyMetaData.Option_UnitStressSmall.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitForce() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitForce.id] as UnitPe);
    }
    public set unitForce(value) {
        this.model[PropertyMetaData.Option_UnitForce.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitMoment() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitMoment.id] as UnitPe);
    }
    public set unitMoment(value) {
        this.model[PropertyMetaData.Option_UnitMoment.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitTemperature() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitTemperature.id] as UnitPe);
    }
    public set unitTemperature(value) {
        this.model[PropertyMetaData.Option_UnitTemperature.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitForcePerLength() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitForcePerLength.id] as UnitPe);
    }
    public set unitForcePerLength(value) {
        this.model[PropertyMetaData.Option_UnitForcePerLength.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitMomentPerLength() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitMomentPerLength.id] as UnitPe);
    }
    public set unitMomentPerLength(value) {
        this.model[PropertyMetaData.Option_UnitMomentPerLength.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitDensity() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitDensity.id] as UnitPe);
    }
    public set unitDensity(value) {
        this.model[PropertyMetaData.Option_UnitDensity.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get unitAreaPerLength() {
        return fromPeUnit(this.model[PropertyMetaData.Option_UnitAreaPerLength.id] as UnitPe);
    }
    public set unitAreaPerLength(value) {
        this.model[PropertyMetaData.Option_UnitAreaPerLength.id] = value != null
            ? toPeUnit(value)
            : null;
    }

    public get baseplateSize() {
        return this.model[PropertyMetaData.AnchorPlate_AnchorPlateSize.id] as BaseplateSize;
    }
    public set baseplateSize(value) {
        this.model[PropertyMetaData.AnchorPlate_AnchorPlateSize.id] = value;
    }

    public get isBridge() {
        return this.model[PropertyMetaData.Loads_IsBridge.id] as boolean;
    }
    public set isBridge(value) {
        this.model[PropertyMetaData.Loads_IsBridge.id] = value;
    }

    public get gammaPermanentSup() {
        return this.model[PropertyMetaData.Loads_Gamma_perm_sup.id] as number;
    }
    public set gammaPermanentSup(value) {
        this.model[PropertyMetaData.Loads_Gamma_perm_sup.id] = value;
    }

    public get gammaPermanentInf() {
        return this.model[PropertyMetaData.Loads_Gamma_perm_inf.id] as number;
    }
    public set gammaPermanentInf(value) {
        this.model[PropertyMetaData.Loads_Gamma_perm_inf.id] = value;
    }

    public get gammaVarHBuilding() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_building.id] as number;
    }
    public set gammaVarHBuilding(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_building.id] = value;
    }

    public get gammaVarVBuilding() {
        return this.model[PropertyMetaData.Loads_Gamma_var_v_building.id] as number;
    }
    public set gammaVarVBuilding(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_v_building.id] = value;
    }

    public get gammaVarHWind() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_wind.id] as number;
    }
    public set gammaVarHWind(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_wind.id] = value;
    }

    public get gammaVarHBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_bridge.id] as number;
    }
    public set gammaVarHBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_bridge.id] = value;
    }

    public get gammaVarVBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_v_bridge.id] as number;
    }
    public set gammaVarVBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_v_bridge.id] = value;
    }

    public get gammaVarHWindBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_wind_bridge.id] as number;
    }
    public set gammaVarHWindBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_wind_bridge.id] = value;
    }

    public get combiPsi0H() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_h.id] as number;
    }
    public set combiPsi0H(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_h.id] = value;
    }

    public get combiPsi0V() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_v.id] as number;
    }
    public set combiPsi0V(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_v.id] = value;
    }

    public get combiPsi0HWind() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_h_wind.id] as number;
    }
    public set combiPsi0HWind(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_h_wind.id] = value;
    }

    public get punctualLoadInWorstPosition() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInWorstPosition.id] as boolean;
    }
    public set punctualLoadInWorstPosition(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInWorstPosition.id] = value;
    }

    public get verticalPunctualLoadInWorstPosition() {
        return this.model[PropertyMetaData.Loads_VerticalPunctualLoadInWorstPosition.id] as boolean;
    }

    public get isinfillPunctualLoadOutwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get isinfillPunctualLoadInwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillPunctualLoadInwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get NRk() {
        return this.model[PropertyMetaData.Loads_OnSiteTestsValues_N_Rk.id] as number;
    }
    public set NRk(value: number) {
        this.model[PropertyMetaData.Loads_OnSiteTestsValues_N_Rk.id] = value;
    }

    public get VRk() {
        return this.model[PropertyMetaData.Loads_OnSiteTestsValues_V_Rk.id] as number;
    }
    public set VRk(value: number) {
        this.model[PropertyMetaData.Loads_OnSiteTestsValues_V_Rk.id] = value;
    }

    public get brickGroup() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BrickGroup] as BrickGroup[];
        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_BrickGroup.id] as number);
    }

    public get brickSize() {
        return this.model[PropertyMetaData.BaseMaterial_BrickSize.id] as BrickSize;
    }
    public set brickSize(value) {
        this.model[PropertyMetaData.BaseMaterial_BrickSize.id] = value;
    }

    public get reportTemplateId() {
        return this.model[PropertyMetaData.Option_ReportTemplateId.id] as number;
    }
    public set reportTemplateId(value) {
        this.model[PropertyMetaData.Option_ReportTemplateId.id] = value;
    }

    public get reportFateningPoint() {
        return this.model[PropertyMetaData.Option_ReportFasteningPoint.id] as string;
    }
    public set reportFateningPoint(value) {
        this.model[PropertyMetaData.Option_ReportFasteningPoint.id] = value;
    }

    public get reportNotes() {
        return this.model[PropertyMetaData.Option_ReportNotes.id] as string;
    }
    public set reportNotes(value) {
        this.model[PropertyMetaData.Option_ReportNotes.id] = value;
    }

    public get customStiffeners() {
        return this.model[PropertyMetaData.Stiffener_CustomStiffener.id] as CustomStiffenerEntity[];
    }
    public set customStiffeners(value) {
        this.model[PropertyMetaData.Stiffener_CustomStiffener.id] = value;
    }

    public get anchorFamily() {
        const codeList = this.designData.designCodeLists[this.globalMetaProperties.productCodeListProperty] as AnchorFamily[];
        return codeList.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get concreteType() {
        return this.model[PropertyMetaData.BaseMaterial_ConcreteType.id] as ConcreteType;
    }

    public get isConcreteTypeSFRC() {
        return this.concreteType == ConcreteType.SteelFiberReinforcedConcrete;
    }

    public get concreteCharacteristic() {
        const codeList = this.designData.designCodeLists[DesignCodeList.ConcreteCharacteristic] as ConcreteCharacteristicEntity[];
        const concreteCharacteristicId = this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id] as number;

        return codeList.find((item) => item.id == concreteCharacteristicId);
    }

    public get profileFamily() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_ProfileFamilyId.id]);
    }

    public get concreteCharacteristicValue() {
        return this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id] as ConcreteCharacteristic;
    }
    public set concreteCharacteristicValue(value) {
        this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id] = value;
    }

    public get stiffenerShape() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerShape.id] as StiffenerShapeId;
    }

    public get concreteGrade() {
        const codeList = this.designData.designCodeLists[DesignCodeList.ConcreteGrade] as ConcreteGrade[];
        const concreteGradeId = this.model[PropertyMetaData.BaseMaterial_ConcreteGrade.id] as number;

        return codeList.find((item) => item.id == concreteGradeId);
    }

    public get supplementaryReinforcementCategory() {
        return this.model[PropertyMetaData.SupplementaryReinforcement_ReinforcementCategory.id] as SupplementaryReinforcementCategoryEnum;
    }

    public get customCompressiveStrength() {
        return this.model[PropertyMetaData.BaseMaterial_CustomCompressiveStrength.id] as number;
    }

    public get anchorPlateExists() {
        return (this.model[PropertyMetaData.AnchorPlate_PlateShape.id] as PlateShape) != PlateShape.None;
    }

    public get newAndImportLoadDisabled() {
        return this.model[PropertyMetaData.Loads_NewAndImportLoad.id] as boolean;
    }

    public get anchorPlateStressImage() {
        return this.model[PropertyMetaData.AnchorPlate_StressImage.id] as string;
    }

    public get measureBaseMaterialEdgeFrom() {
        return this.model[PropertyMetaData.Option_MeasureBaseMaterialEdgeFrom.id] as number;
    }
    public set measureBaseMaterialEdgeFrom(value) {
        this.model[PropertyMetaData.Option_MeasureBaseMaterialEdgeFrom.id] = value != null ? value : null;
    }

    public get buildingCategory() {
        const codelist = this.designData.designCodeLists[DesignCodeList.BuildingCategory] as BuildingCategory[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Application_BuildingCategory.id]);
    }

    public get punctualRailLoadOutwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadRailOutwards.id] as number;
    }

    public get punctualRailLoadInwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadRailInwards.id] as number;
    }

    public get jointThicknessHorizontal() {
        return this.model[PropertyMetaData.BaseMaterial_JointThicknessHorizontal.id] as number;
    }

    public get jointThicknessVertical() {
        return this.model[PropertyMetaData.BaseMaterial_JointThicknessVertical.id] as number;
    }

    public get isInfillLoadInwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillLoadInwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get isInfillLoadOutwardsHIdden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillLoadOutwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get measureAnchorPlate() {
        return this.model[PropertyMetaData.Option_MeasureAnchorPlate.id] as number;
    }
    public set measureAnchorPlate(value) {
        this.model[PropertyMetaData.Option_MeasureAnchorPlate.id] = value != null ? value : null;
    }

    public get designMethodGroupsAvailable() {
        let designMethodGroups = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[])
            .filter((designMethodGroup) => designMethodGroup.designStandards?.includes(this.designStandard.id ?? 0));

        // filter design method groups by design type and region
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => this.designType.allowedDesignMethodGroupRegions?.some((row) => row.RegionId == this.region.id && row.DesignMethodGroups.includes(designMethodGroup.id ?? 0)));

        return designMethodGroups;
    }

    public get loadsCalculationMode() {
        return this.model[PropertyMetaData.Loads_LoadCombinationsCalculationType.id] as LoadCombinationsCalculationType | null;
    }

    public set loadsCalculationMode(value) {
        this.model[PropertyMetaData.Loads_LoadCombinationsCalculationType.id] = value != null ? value : null;
    }

    public get reportLoadCombination() {
        return this.model[PropertyMetaData.Option_ReportLoadCombination.id] as string;
    }
    public set reportLoadCombination(value) {
        this.model[PropertyMetaData.Option_ReportLoadCombination.id] = value;
    }

    public get profileExists() {
        return this.model[PropertyMetaData.Profile_UseProfile.id] as boolean;
    }

    public get IsAnchorPlatePresent() {
        return this.model[PropertyMetaData.AnchorPlate_Exists.id];
    }

    public get designMethodGroup() {
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];
        return codeList.find((designMethodGroup) => designMethodGroup.id == this.model[PropertyMetaData.Option_DesignMethodGroupId.id] as number);
    }
    public set designMethodGroup(value) {
        this.model[PropertyMetaData.Option_DesignMethodGroupId.id] = value != null ? value.id : null;
    }

    public get hasIntegratedProfile() {
        return this.isLedgerAngle;
    }

    public get standOffNoneIfNull() {
        const codeList = this.designData.designCodeLists[DesignCodeList.StandOff] as StandOff[];
        const standOffId = this.model[PropertyMetaData.AnchorPlate_StandoffType.id] as number;
        const item = codeList.find((itm) => itm.id == standOffId);
        if (item != null) {
            return item;
        }

        return codeList.find((itm) => itm.id == StandOffType.None);
    }

    public get standOffDistance() {
        return this.model[PropertyMetaData.AnchorPlate_StandoffDistance.id] as number;
    }

    public get noLoads() {
        return (this.forceX == null || this.forceX == 0)
            && (this.forceY == null || this.forceY == 0)
            && (this.forceZ == null || this.forceZ == 0)
            && (this.momentX == null || this.momentX == 0)
            && (this.momentY == null || this.momentY == 0)
            && (this.momentZ == null || this.momentZ == 0);
    }

    public get forceZ() {
        return this.model[PropertyMetaData.Loads_ForceZ.id] as number;
    }
    public set forceZ(value) {
        this.model[PropertyMetaData.Loads_ForceZ.id] = value;
    }

    public get forceX() {
        return this.model[PropertyMetaData.Loads_ForceX.id] as number;
    }
    public set forceX(value) {
        this.model[PropertyMetaData.Loads_ForceX.id] = value;
    }

    public get forceY() {
        return this.model[PropertyMetaData.Loads_ForceY.id] as number;
    }
    public set forceY(value) {
        this.model[PropertyMetaData.Loads_ForceY.id] = value;
    }

    public get momentZ() {
        return this.model[PropertyMetaData.Loads_MomentZ.id] as number;
    }
    public set momentZ(value) {
        this.model[PropertyMetaData.Loads_MomentZ.id] = value;
    }

    public get momentX() {
        return this.model[PropertyMetaData.Loads_MomentX.id] as number;
    }
    public set momentX(value) {
        this.model[PropertyMetaData.Loads_MomentX.id] = value;
    }

    public get momentY() {
        return this.model[PropertyMetaData.Loads_MomentY.id] as number;
    }
    public set momentY(value) {
        this.model[PropertyMetaData.Loads_MomentY.id] = value;
    }

    public get isAnchorReinforcement() {
        return this.model[PropertyMetaData.BaseMaterial_IsAnchorReinforcement.id] as boolean;
    }
    public set isAnchorReinforcement(value) {
        this.model[PropertyMetaData.BaseMaterial_IsAnchorReinforcement.id] = value;
    }

    public get filledHolesSOFA() {
        return this.model[PropertyMetaData.AnchorLayout_FillHolesSOFA.id] as boolean;
    }
    public set filledHolesSOFA(value) {
        this.model[PropertyMetaData.AnchorLayout_FillHolesSOFA.id] = value;
    }

    public get filledHolesETAG() {
        return this.model[PropertyMetaData.AnchorLayout_FillHolesETAG.id] as boolean;
    }

    public get isLedgerAngle() {
        return (this.model[PropertyMetaData.AnchorPlate_LedgerAngleShape.id] as PlateShape) == PlateShape.LedgerAngle;
    }

    public get isWHGConcreteWithCoating() {
        return (this.model[PropertyMetaData.BaseMaterial_WHGType.id] as WHGTypeEnum) == WHGTypeEnum.Coating;
    }

    public get anchorPlateShape() {
        return this.model[PropertyMetaData.AnchorPlate_PlateShape.id] as PlateShape;
    }

    public set anchorPlateShape(value) {
        this.model[PropertyMetaData.AnchorPlate_PlateShape.id] = value;
    }

    public get anchorLayout() {
        return this.model[PropertyMetaData.AnchorLayout_Layout.id] as number;
    }
    public set anchorLayout(value) {
        this.model[PropertyMetaData.AnchorLayout_Layout.id] = value;
    }

    public get anchorLayoutSymmetry() {
        return this.model[PropertyMetaData.AnchorLayout_AnchorLayoutSymmetry.id] as boolean;
    }

    public set anchorLayoutSymmetry(value) {
        this.model[PropertyMetaData.AnchorLayout_AnchorLayoutSymmetry.id] = value;
    }

    public get stainlessSteel() {
        return this.model[PropertyMetaData.Option_StainlessSteel.id] as boolean;
    }
    public set stainlessSteel(value) {
        this.model[PropertyMetaData.Option_StainlessSteel.id] = value;
    }

    public get isStainlessSteelDisabled() {
        const item = this.properties.properties[PropertyMetaData.Option_StainlessSteel.id];
        return item !== undefined ? item.disabled : true;
    }

    public get isStainlessSteelHidden() {
        const item = this.properties.properties[PropertyMetaData.Option_StainlessSteel.id];
        return item !== undefined ? item.hidden : true;
    }

    public get profileWidth() {
        return this.model[PropertyMetaData.Profile_CustomWidth.id] as number;
    }

    public get profileWidth2() {
        return this.model[PropertyMetaData.Profile_CustomWidth2.id] as number;
    }

    public get profileHeight() {
        return this.model[PropertyMetaData.Profile_CustomHeight.id] as number;
    }

    public get profileFlangeThickness() {
        return this.model[PropertyMetaData.Profile_CustomFlangeThickness.id] as number;
    }

    public get weldOnWebs() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] != WeldLocation.None;
    }

    public get weldOnFlanges() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] != WeldLocation.None;
    }

    public get directWelds() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] != WeldLocation.None;
    }

    public get weldOnStiffeners() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] != WeldLocation.None;
    }

    public get webWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] as WeldLocation;
    }

    public get flangeWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] as WeldLocation;
    }

    public get directWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] as WeldLocation;
    }

    public get directWeldLength() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLength.id] as number;
    }

    public get webWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldThickness.id] as number;
    }

    public get flangeWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldThickness.id] as number;
    }

    public get directWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldThickness.id] as number;
    }

    public get webWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldMaterial.id] as number;
    }

    public get flangeWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldMaterial.id] as number;
    }

    public get directWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldMaterial.id] as number;
    }

    public get stiffenerLayout() {
        const codelist = this.designData.designCodeLists[DesignCodeList.StiffenerLayout] as StiffenerLayout[];

        return codelist?.find((item) => item.id == this.model[PropertyMetaData.AnchorPlate_StiffenerLayout.id]);
    }

    public get isCustomStiffenerLayout() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerLayout.id] == 0;
    }

    public get isStiffenerPresent() {
        if (!this.isCBFEMCalculation) {
            return false;
        }

        if (this.isCustomStiffenerLayout && this.customStiffeners != null && this.customStiffeners.length > 0) {
            return true;
        }

        if (this.stiffenerLayout != null && this.stiffenerLayout.stiffenerLocation != StiffenerLocation.None) {
            return true;
        }

        return false;
    }

    public get stiffenerRadialOffset() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerRadialOffset.id] as number;
    }

    public get stiffenerWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldMaterial.id] as number;
    }

    public get stiffenerHeight() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerHeight.id] as number;
    }

    public get stiffenerWidth() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWidth.id] as number;
    }

    public get linearLoadOutwards() {
        return this.model[PropertyMetaData.Loads_LinearLoadOutwards.id] as number;
    }
    public set linearLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_LinearLoadOutwards.id] = value;
    }

    public get linearLoadInwards() {
        return this.model[PropertyMetaData.Loads_LinearLoadInwards.id] as number;
    }
    public set linearLoadInwards(value) {
        this.model[PropertyMetaData.Loads_LinearLoadInwards.id] = value;
    }

    public get linearLoadOutwardsHeight() {
        return this.model[PropertyMetaData.Loads_LinearLoadOutwardsHeight.id] as number;
    }
    public set linearLoadOutwardsHeight(value) {
        this.model[PropertyMetaData.Loads_LinearLoadOutwardsHeight.id] = value;
    }

    public get linearLoadInwardsHeight() {
        return this.model[PropertyMetaData.Loads_LinearLoadInwardsHeight.id] as number;
    }
    public set linearLoadInwardsHeight(value) {
        this.model[PropertyMetaData.Loads_LinearLoadInwardsHeight.id] = value;
    }

    public get punctualLoadOutwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadOutwards.id] as number;
    }
    public set punctualLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadOutwards.id] = value;
    }

    public get punctualLoadInwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInwards.id] as number;
    }
    public set punctualLoadInwards(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInwards.id] = value;
    }

    public get punctualLoadInwardsHeight() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInwardsHeight.id] as number;
    }
    public set punctualLoadInwardsHeight(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInwardsHeight.id] = value;
    }

    public get punctualLoadOutwardsHeight() {
        return this.model[PropertyMetaData.Loads_PunctualLoadOutwardsHeight.id] as number;
    }
    public set punctualLoadOutwardsHeight(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadOutwardsHeight.id] = value;
    }

    public get infillLoadOutwards() {
        return this.model[PropertyMetaData.Loads_InfillLoadOutwards.id] as number;
    }
    public set infillLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_InfillLoadOutwards.id] = value;
    }

    public get infillLoadInwards() {
        return this.model[PropertyMetaData.Loads_InfillLoadInwards.id] as number;
    }
    public set infillLoadInwards(value) {
        this.model[PropertyMetaData.Loads_InfillLoadInwards.id] = value;
    }

    public get infillPunctualLoadOutwards() {
        return this.model[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id] as number;
    }
    public set infillPunctualLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id] = value;
    }

    public get infillPunctualLoadInwards() {
        return this.model[PropertyMetaData.Loads_InfillPunctualLoadInwards.id] as number;
    }
    public set infillPunctualLoadInwards(value) {
        this.model[PropertyMetaData.Loads_InfillPunctualLoadInwards.id] = value;
    }

    public get verticalLoad() {
        return this.model[PropertyMetaData.Loads_VerticalLoad.id] as number;
    }
    public set verticalLoad(value) {
        this.model[PropertyMetaData.Loads_VerticalLoad.id] = value;
    }

    public get verticalPunctualLoad() {
        return this.model[PropertyMetaData.Loads_VerticalPunctualLoad.id] as number;
    }
    public set verticalPunctualLoad(value) {
        this.model[PropertyMetaData.Loads_VerticalPunctualLoad.id] = value;
    }

    public get stiffenerThickness() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerThickness.id] as number;
    }

    public get stiffenerHorizontalEdgeLength() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerHorizontalEdgeLength.id] as number;
    }

    public get stiffenerVerticalEdgeLength() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerVerticalEdgeLength.id] as number;
    }

    public get stiffenerWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldThickness.id] as number;
    }

    public get stiffenerWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] as WeldLocation;
    }

    public get profileSize() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_ProfileSizeId.id]);
    }

    public get profileThickness() {
        return this.model[PropertyMetaData.Profile_CustomThickness.id] as number;
    }

    public get profileAngle() {
        return this.model[PropertyMetaData.Profile_Angle.id] as number;
    }

    public get profileOffsetX() {
        return this.model[PropertyMetaData.Profile_OffsetX.id] as number;
    }

    public get profileOffsetY() {
        return this.model[PropertyMetaData.Profile_OffsetY.id] as number;
    }

    public get showAllLoads() {
        return this.model[PropertyMetaData.Loads_ShowAllLoads.id] as boolean;
    }

    public get isFatigueExpertMode() {
        return this.model[PropertyMetaData.Loads_IsFatigueExpertMode.id] as boolean;
    }
    public set isFatigueExpertMode(value) {
        this.model[PropertyMetaData.Loads_IsFatigueExpertMode.id] = value;
    }

    public get isAnchorENFatigueCompliant() {
        return this.model[PropertyMetaData.AnchorLayout_IsAnchorENFatigueCompliant.id] as boolean;
    }

    public get anchorType() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup?.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as unknown as AnchorTypeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorType] as unknown as AnchorTypeEntity[];

        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Type.id]);
    }

    public get anchorSize() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup?.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorSizeAC58] as AnchorSizeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSizeEntity[];

        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Size.id]);
    }

    public get isDynamicFatigue() {
        const eurocodeAllowed =
            (
                this.designStandard.id == DesignStandardEnum.EC2
                ||
                this.designStandard.id == DesignStandardEnum.NZ
                ||
                this.designStandard.id == DesignStandardEnum.IS
                ||
                this.designStandard.id == DesignStandardEnum.MS
            )
            &&
            (
                this.designMethodGroup?.id == DesignMethodGroupEnum.EN_Based
                ||
                this.designMethodGroup?.id == DesignMethodGroupEnum.SOFA_Based
                ||
                (
                    this.designMethodGroup?.id == DesignMethodGroupEnum.ETA_Based
                    &&
                    this.isAnchorENFatigueCompliant
                )
            );

        const hongKongAllowed = this.designStandard.id == DesignStandardEnum.HK &&
            (
                this.designMethodGroup?.id == DesignMethodGroupEnum.EN_AnchorDesign
                ||
                this.designMethodGroup?.id == DesignMethodGroupEnum.SOFA_Based
            );

        return this.designType.id == DesignTypeId.Concrete
            && this.isFatigueDesign
            && (eurocodeAllowed || hongKongAllowed);
    }

    public get windZone() {
        const codeList = this.designData.designCodeLists[DesignCodeList.WindZone] as WindZone[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.Application_WindZone.id]);
    }
    public set windZone(value) {
        this.model[PropertyMetaData.Application_WindZone.id] = value != null ? value.id : null;
    }

    public get buildingWidth() {
        return this.model[PropertyMetaData.Application_BuildingWidth.id] as number;
    }
    public set buildingWidth(value: number | undefined) {
        this.model[PropertyMetaData.Application_BuildingWidth.id] = value;
    }

    public get buildingHeight() {
        return this.model[PropertyMetaData.Application_BuildingHeight.id] as number;
    }
    public set buildingHeight(value: number | undefined) {
        this.model[PropertyMetaData.Application_BuildingHeight.id] = value;
    }

    public get buildingDepth() {
        return this.model[PropertyMetaData.Application_BuildingDepth.id] as number;
    }
    public set buildingDepth(value: number | undefined) {
        this.model[PropertyMetaData.Application_BuildingDepth.id] = value;
    }

    public get buildingZone() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BuildingArea] as BuildingArea[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.Application_BuildingZone.id]);
    }
    public set buildingZone(value) {
        this.model[PropertyMetaData.Application_BuildingZone.id] = value != null ? value.id : null;
    }

    public get isFreeStandingWall() {
        const item = this.model[PropertyMetaData.Application_IsFreeStandingWall.id];
        return item !== undefined ? item as boolean : true;
    }
    public set isFreeStandingWall(value) {
        this.model[PropertyMetaData.Application_IsFreeStandingWall.id] = value;
    }

    public get handrailLength() {
        return this.model[PropertyMetaData.Application_HandrailLength.id] as number;
    }
    public set handrailLength(value: number | undefined) {
        this.model[PropertyMetaData.Application_HandrailLength.id] = value;
    }

    public get handrailHeightOverGround() {
        return this.model[PropertyMetaData.Application_HandrailHeightOverGround.id] as number;
    }
    public set handrailHeightOverGround(value: number | undefined) {
        this.model[PropertyMetaData.Application_HandrailHeightOverGround.id] = value;
    }

    public get stairWidth() {
        return this.model[PropertyMetaData.BaseMaterial_StairLength.id] as number;
    }
    public get stairHeight() {
        return this.model[PropertyMetaData.BaseMaterial_StairHeight.id] as number;
    }

    public get altitude() {
        return this.model[PropertyMetaData.Application_Altitude.id] as number;
    }
    public set altitude(value: number | undefined) {
        this.model[PropertyMetaData.Application_Altitude.id] = value;
    }

    public get distanceToSeaside() {
        return this.model[PropertyMetaData.Application_DistanceToSeaside.id] as number;
    }
    public set distanceToSeaside(value: number | undefined) {
        this.model[PropertyMetaData.Application_DistanceToSeaside.id] = value;
    }

    public get referenceVelocityPressure() {
        return this.model[PropertyMetaData.Application_WindVelocityPressure.id] as number;
    }
    public set referenceVelocityPressure(value: number | undefined) {
        this.model[PropertyMetaData.Application_WindVelocityPressure.id] = value;
    }

    public get fundamentalWindVelocity() {
        return this.model[PropertyMetaData.Loads_FundamentalWindVelocity.id] as number;
    }
    public set fundamentalWindVelocity(value: number | undefined) {
        this.model[PropertyMetaData.Loads_FundamentalWindVelocity.id] = value;
    }

    public get windPressure() {
        return this.model[PropertyMetaData.Application_WindPressure.id] as number;
    }
    public set windPressure(value: number | undefined) {
        this.model[PropertyMetaData.Application_WindPressure.id] = value;
    }

    public get windUplift() {
        return this.model[PropertyMetaData.Application_WindUplift.id] as number;
    }
    public set windUplift(value: number | undefined) {
        this.model[PropertyMetaData.Application_WindUplift.id] = value;
    }

    public get terrainCategoryId() {
        return this.model[PropertyMetaData.Application_TerrainCategoryId.id] as number;
    }
    public set terrainCategoryId(value: number | undefined) {
        this.model[PropertyMetaData.Application_TerrainCategoryId.id] = value;
    }

    public get windLoadStateId() {
        const cityId = this.model[PropertyMetaData.Application_CityAndStateId.id] as number;
        const selectedCity = this.designData.designCodeLists[DesignCodeList.WindLoadCity].find((item) => item.id == cityId);
        const selectedState = this.designData.designCodeLists[DesignCodeList.WindLoadState].find((item) => item.name == (selectedCity as WindLoadCity).federalState);
        return selectedState != null && selectedState != undefined ? selectedState.id : null;
    }

    public get windLoadCityId() {
        return this.model[PropertyMetaData.Application_CityAndStateId.id] as number;
    }
    public set windLoadCityId(value: number | undefined) {
        this.model[PropertyMetaData.Application_CityAndStateId.id] = value;
    }

    public get zipCodeHandrailLoadsId() {
        return this.model[PropertyMetaData.Application_ZipCodeHandrailLoadsId.id] as number;
    }
    public set zipCodeHandrailLoadsId(value: number | undefined) {
        this.model[PropertyMetaData.Application_ZipCodeHandrailLoadsId.id] = value;
    }

    public get roughnessClassId() {
        return this.model[PropertyMetaData.Application_RoughnessClassId.id] as number;
    }
    public set roughnessClassId(value: number | undefined) {
        this.model[PropertyMetaData.Application_RoughnessClassId.id] = value;
    }

    public get returnPeriod() {
        return this.model[PropertyMetaData.Application_ReturnPeriod.id] as number;
    }
    public set returnPeriod(value: number | undefined) {
        this.model[PropertyMetaData.Application_ReturnPeriod.id] = value;
    }

    public get distanceUpwindToShorelineId() {
        return this.model[PropertyMetaData.Application_DistanceUpwindToShorelineId.id] as number;
    }
    public set distanceUpwindToShorelineId(value: number | undefined) {
        this.model[PropertyMetaData.Application_DistanceUpwindToShorelineId.id] = value;
    }

    public get distanceInsideTownTerrainId() {
        return this.model[PropertyMetaData.Application_DistanceInsideTownTerrainId.id] as number;
    }
    public set distanceInsideTownTerrainId(value: number | undefined) {
        this.model[PropertyMetaData.Application_DistanceInsideTownTerrainId.id] = value;
    }

    public get basicWindVelocityId() {
        return this.model[PropertyMetaData.Application_BasicWindVelocityId.id] as number;
    }
    public set basicWindVelocityId(value: number | undefined) {
        this.model[PropertyMetaData.Application_BasicWindVelocityId.id] = value;
    }

    public get referenceWindVelocityId() {
        return this.model[PropertyMetaData.Application_ReferenceWindVelocityId.id] as number;
    }
    public set referenceWindVelocityId(value: number | undefined) {
        this.model[PropertyMetaData.Application_ReferenceWindVelocityId.id] = value;
    }

    public get terrainTypeId() {
        return this.model[PropertyMetaData.Application_TerrainType.id] as number;
    }
    public set terrainTypeId(value: number | undefined) {
        this.model[PropertyMetaData.Application_TerrainType.id] = value;
    }

    public get isOrographyRelevant() {
        return this.model[PropertyMetaData.Application_IsOrographyRelevant.id] as boolean;
    }
    public set isOrographyRelevant(value) {
        this.model[PropertyMetaData.Application_IsOrographyRelevant.id] = value;
    }

    public get orographyInputH() {
        return this.model[PropertyMetaData.Application_H.id] as number;
    }
    public set orographyInputH(value: number | undefined) {
        this.model[PropertyMetaData.Application_H.id] = value;
    }

    public get orographyInputX() {
        return this.model[PropertyMetaData.Application_X.id] as number;
    }
    public set orographyInputX(value: number | undefined) {
        this.model[PropertyMetaData.Application_X.id] = value;
    }

    public get orographyInputLu() {
        return this.model[PropertyMetaData.Application_Lu.id] as number;
    }
    public set orographyInputLu(value: number | undefined) {
        this.model[PropertyMetaData.Application_Lu.id] = value;
    }

    public get orographyInputLd() {
        return this.model[PropertyMetaData.Application_Ld.id] as number;
    }
    public set orographyInputLd(value: number | undefined) {
        this.model[PropertyMetaData.Application_Ld.id] = value;
    }

    public get beamLength() {
        return this.model[PropertyMetaData.BaseMaterial_BeamLength.id] as number;
    }

    public get balustradeHeight() {
        const codelist = this.designData.designCodeLists[DesignCodeList.HandrailApplication] as HandrailApplicationTypeEntity[];
        const application = codelist.find((app) => app.id == this.model[PropertyMetaData.Application_Application.id]);

        if (application?.handrailApplicationType != HandrailApplicationType.Balustrade) {
            return 0;
        }

        return this.model[PropertyMetaData.BaseMaterial_BalustradeHeight.id] as number;
    }

    public get customPictures() {
        return this.model[PropertyMetaData.Option_CustomPictures.id] as string[];
    }
    public set customPictures(val: string[]) {
        this.model[PropertyMetaData.Option_CustomPictures.id] = val;
    }

    public get noteLoadCaseResultingAnchorForces() {
        return this.model[PropertyMetaData.Option_NoteLoadCaseResultingAnchorForces.id] as string;
    }
    public set noteLoadCaseResultingAnchorForces(val: string) {
        this.model[PropertyMetaData.Option_NoteLoadCaseResultingAnchorForces.id] = val;
    }

    public get noteTensionLoad() {
        return this.model[PropertyMetaData.Option_NoteTensionLoad.id] as string;
    }
    public set noteTensionLoad(val: string) {
        this.model[PropertyMetaData.Option_NoteTensionLoad.id] = val;
    }

    public get noteShearLoad() {
        return this.model[PropertyMetaData.Option_NoteShearLoad.id] as string;
    }
    public set noteShearLoad(val: string) {
        this.model[PropertyMetaData.Option_NoteShearLoad.id] = val;
    }

    public get noteCombinedTensionAndShearLoad() {
        return this.model[PropertyMetaData.Option_NoteCombinedTensionAndShearLoad.id] as string;
    }
    public set noteCombinedTensionAndShearLoad(val: string) {
        this.model[PropertyMetaData.Option_NoteCombinedTensionAndShearLoad.id] = val;
    }

    public get noteDisplacements() {
        return this.model[PropertyMetaData.Option_NoteDisplacements.id] as string;
    }
    public set noteDisplacements(val: string) {
        this.model[PropertyMetaData.Option_NoteDisplacements.id] = val;
    }

    public get noteInstallationData() {
        return this.model[PropertyMetaData.Option_NoteInstallationData.id] as string;
    }
    public set noteInstallationData(val: string) {
        this.model[PropertyMetaData.Option_NoteInstallationData.id] = val;
    }

    public get reportTypeId() {
        return this.model[PropertyMetaData.Option_ReportTypeId.id] as ReportType;
    }
    public set reportTypeId(value) {
        this.model[PropertyMetaData.Option_ReportTypeId.id] = value;
    }

    public get reportLanguageId() {
        return this.model[PropertyMetaData.Option_ReportLanguageLcid.id] as number;
    }
    public set reportLanguageId(value) {
        this.model[PropertyMetaData.Option_ReportLanguageLcid.id] = value;
    }

    public get reportPaperSizeId() {
        return this.model[PropertyMetaData.Option_ReportPaperSizeId.id] as PaperSize;
    }
    public set reportPaperSizeId(value) {
        this.model[PropertyMetaData.Option_ReportPaperSizeId.id] = value;
    }

    public get isAnchorPlateStressNotPossible() {
        return !this.designData.reportData?.FeCalculationComplete;
    }

    public get decisiveLoadCombination() {
        if (this.designData.reportData?.DecisiveLoadCombinationIndex != null && this.loadCombinations != null && this.designData.reportData.DecisiveLoadCombinationIndex < this.loadCombinations.length) {
            return this.loadCombinations[this.designData.reportData.DecisiveLoadCombinationIndex];
        }
        return null;
    }

    public get reportFirstPage() {
        return this.model[PropertyMetaData.Option_ReportFirstPage.id] as number;
    }
    public set reportFirstPage(value) {
        this.model[PropertyMetaData.Option_ReportFirstPage.id] = value;
    }

    public get reportCompanyName() {
        return this.model[PropertyMetaData.Option_ReportCompanyName.id] as string;
    }
    public set reportCompanyName(value) {
        this.model[PropertyMetaData.Option_ReportCompanyName.id] = value;
    }

    public get reportAddress() {

        return this.model[PropertyMetaData.Option_ReportAddress.id] as string;
    }
    public set reportAddress(value) {
        this.model[PropertyMetaData.Option_ReportAddress.id] = value;
    }

    public get reportContactPerson() {
        return this.model[PropertyMetaData.Option_ReportContactPerson.id] as string;
    }
    public set reportContactPerson(value) {
        this.model[PropertyMetaData.Option_ReportContactPerson.id] = value;
    }

    public get reportPhoneNumber() {
        return this.model[PropertyMetaData.Option_ReportPhoneNumber.id] as string;
    }
    public set reportPhoneNumber(value) {
        this.model[PropertyMetaData.Option_ReportPhoneNumber.id] = value;
    }

    public get reportEmail() {
        return this.model[PropertyMetaData.Option_ReportEmail.id] as string;
    }
    public set reportEmail(value) {
        this.model[PropertyMetaData.Option_ReportEmail.id] = value;
    }

    public get trimbleConnectLocation() {
        return this.model[PropertyMetaData.Option_TrimbleConnectLocation.id] as string;
    }
    public set trimbleConnectLocation(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectLocation.id] = value;
    }

    public get trimbleConnectReportName() {
        return this.model[PropertyMetaData.Option_TrimbleConnectReportName.id] as string;
    }
    public set trimbleConnectReportName(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectReportName.id] = value;
    }

    public get trimbleConnectFolderId() {
        return this.model[PropertyMetaData.Option_TrimbleConnectFolderId.id] as string;
    }
    public set trimbleConnectFolderId(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectFolderId.id] = value;
    }

    public get anchorFastenerTypeImage() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup?.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorTypeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorTypeEntity[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Type.id]);
    }

    public get anchorFastenerFamilyImage() {
        const codeList = this.designData.designCodeLists[this.globalMetaProperties.productCodeListProperty] as AnchorFamily[];

        return codeList.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get brickLayout() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BrickLayout] as BrickLayout[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_BrickLayout.id]);
    }

    public get trimbleConnectUpload() {
        return this.model[PropertyMetaData.Option_TrimbleConnectUpload.id] as boolean;
    }
    public set trimbleConnectUpload(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectUpload.id] = value;
    }

    public get edgeDistanceDisplayType() {
        return this.model[PropertyMetaData.Option_EdgeDistanceDisplayType.id] as number;
    }
    public set edgeDistanceDisplayType(value) {
        this.model[PropertyMetaData.Option_EdgeDistanceDisplayType.id] = value != null ? value : null;
    }

    public get anchorPlateFactor() {
        return this.model[PropertyMetaData.Option_AnchorPlateFactor.id] as number;
    }
    public set anchorPlateFactor(value) {
        this.model[PropertyMetaData.Option_AnchorPlateFactor.id] = value != null ? value : null;
    }

    public get metalDeckType() {
        return this.model[PropertyMetaData.BaseMaterial_MetalDeckType.id] as number;
    }

    public get isShearLugPresent() {
        return this.model[PropertyMetaData.Profile_UseShearLug.id] as boolean;
    }

    public get handrailRailProfileFamily() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_HandrailRailProfileFamilyId.id]);
    }

    public get concreteEtagEnOnly() {
        return this.model[PropertyMetaData.Loads_ConcreteEtagEnOnly.id] as boolean;
    }
    public set concreteEtagEnOnly(value) {
        this.model[PropertyMetaData.Loads_ConcreteEtagEnOnly.id] = value;
    }

    public get handrailEtagEnOnly() {
        return this.model[PropertyMetaData.Loads_HandrailEtagEnOnly.id] as boolean;
    }
    public set handrailEtagEnOnly(value) {
        this.model[PropertyMetaData.Loads_HandrailEtagEnOnly.id] = value;
    }

    public get safetyFactorForPermamentLoads() {
        return this.model[PropertyMetaData.Option_SafetyFactorForPermamentLoads.id] as number;
    }
    public set safetyFactorForPermamentLoads(value) {
        this.model[PropertyMetaData.Option_SafetyFactorForPermamentLoads.id] = value != null ? value : null;
    }

    public get safetyFactorForVariableLoads() {
        return this.model[PropertyMetaData.Option_SafetyFactorForVariableLoads.id] as number;
    }
    public set safetyFactorForVariableLoads(value) {
        this.model[PropertyMetaData.Option_SafetyFactorForVariableLoads.id] = value != null ? value : null;
    }

    public get minimumAnchorToProfileDistance() {
        return this.model[PropertyMetaData.Option_MinimumAnchorToProfileDistance.id] as number;
    }
    public set minimumAnchorToProfileDistance(value) {
        this.model[PropertyMetaData.Option_MinimumAnchorToProfileDistance.id] = value != null ? value : null;
    }

    public get minimumConcreteCover() {
        return this.model[PropertyMetaData.Option_MinimumConcreteCover.id] as number;
    }
    public set minimumConcreteCover(value) {
        this.model[PropertyMetaData.Option_MinimumConcreteCover.id] = value != null ? value : null;
    }

    public get concreteSafetyFactorGammaC() {
        return this.model[PropertyMetaData.Option_ConcreteSafetyFactorGammaC.id] as ConcreteSafetyFactorGammaC;
    }
    public set concreteSafetyFactorGammaC(value) {
        this.model[PropertyMetaData.Option_ConcreteSafetyFactorGammaC.id] = value != null ? value : null;
    }

    public get handrailDisplacementLimit() {
        return this.model[PropertyMetaData.Option_HandrailDisplacementLimit.id] as number;
    }
    public set handrailDisplacementLimit(value) {
        this.model[PropertyMetaData.Option_HandrailDisplacementLimit.id] = value != null ? value : null;
    }

    public get useOnSiteTestsData() {
        return this.model[PropertyMetaData.Loads_UseOnSiteTestsData.id] as boolean;
    }
    public set useOnSiteTestsData(value) {
        this.model[PropertyMetaData.Loads_UseOnSiteTestsData.id] = value != null ? value : null;
    }

    public get ostInputsData() {
        return this.model[PropertyMetaData.Option_OstInputsEnabled.id] as boolean;
    }
    public set ostInputsData(value) {
        this.model[PropertyMetaData.Option_OstInputsEnabled.id] = value != null ? value : null;

        const useOnSiteVal = this.useOnSiteTestsData && this.ostInputsData;
        this.useOnSiteTestsData = useOnSiteVal;
    }

    public get steelSafetyFactorGammaM0() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM0.id] as number;
    }
    public set steelSafetyFactorGammaM0(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM0.id] = value;
    }

    public get steelSafetyFactorGammaM1() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM1.id] as number;
    }
    public set steelSafetyFactorGammaM1(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM1.id] = value;
    }

    public get steelSafetyFactorGammaM2() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM2.id] as number;
    }
    public set steelSafetyFactorGammaM2(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM2.id] = value;
    }

    public get steelSafetyFactorGammaM5() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM5.id] as number;
    }
    public set steelSafetyFactorGammaM5(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM5.id] = value;
    }

    public get materialSafetyFactor() {
        return this.model[PropertyMetaData.Option_MaterialSafetyFactor.id] as number;
    }
    public set materialSafetyFactor(value) {
        this.model[PropertyMetaData.Option_MaterialSafetyFactor.id] = value;
    }

    public get handrailConnectionType() {
        return this.model[PropertyMetaData.Application_ConnectionType.id] as HandrailConnectionType;
    }

    public get weldCapacityFactor() {
        return this.model[PropertyMetaData.Option_WeldsCapacityFactor.id] as number;
    }
    public set weldCapacityFactor(value) {
        this.model[PropertyMetaData.Option_WeldsCapacityFactor.id] = value;
    }

    public get concreteCapacityFactor() {
        return this.model[PropertyMetaData.Option_ConcreteCapacityFactor.id] as number;
    }
    public set concreteCapacityFactor(value) {
        this.model[PropertyMetaData.Option_ConcreteCapacityFactor.id] = value;
    }

    public get jointCoefficientBj() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_JointCoefficientBj.id] as number;
    }
    public set jointCoefficientBj(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_JointCoefficientBj.id] = value;
    }

    public get effectiveArea() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveArea.id] as number;
    }
    public set effectiveArea(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveArea.id] = value;
    }

    public get effectiveAreaAISC() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveAreaAISC.id] as number;
    }
    public set effectiveAreaAISC(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveAreaAISC.id] = value;
    }

    public get loadDistributionAngle() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_LoadDistributionAngle.id] as number;
    }
    public set loadDistributionAngle(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_LoadDistributionAngle.id] = value;
    }

    public get alphaCC() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC.id] as number;
    }
    public set alphaCC(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC.id] = value;
    }

    public get concreteInCompressionMethod() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_ConcreteInCompressionMethod.id] as ConcreteInCompressionMethod;
    }
    public set concreteInCompressionMethod(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_ConcreteInCompressionMethod.id] = value;
    }

    public get limitPlasticStrain() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain.id] as number;
    }
    public set limitPlasticStrain(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain.id] = value;
    }

    public get divisionOfSurfaceOfCHS() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS.id] as number;
    }
    public set divisionOfSurfaceOfCHS(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS.id] = value;
    }

    public get numberOfElementsOfEdge() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfElementsOfEdge.id] as number;
    }
    public set numberOfElementsOfEdge(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfElementsOfEdge.id] = value;
    }

    public get numberOfAnalysisIterations() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfAnalysisIterations.id] as number;
    }
    public set numberOfAnalysisIterations(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfAnalysisIterations.id] = value;
    }

    public get divergentIterationsCount() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivergentIterationsCount.id] as number;
    }
    public set divergentIterationsCount(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivergentIterationsCount.id] = value;
    }

    public get minimumSizeOfElement() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_MinimumSizeOfElement.id] as number;
    }
    public set minimumSizeOfElement(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_MinimumSizeOfElement.id] = value;
    }

    public get maximumSizeOfElement() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_MaximumSizeOfElement.id] as number;
    }
    public set maximumSizeOfElement(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_MaximumSizeOfElement.id] = value;
    }

    public get steelCapacityFactor() {
        return this.model[PropertyMetaData.Option_SteelCapacityFactor.id] as number;
    }
    public set steelCapacityFactor(value) {
        this.model[PropertyMetaData.Option_SteelCapacityFactor.id] = value;
    }

    public get inSteelSafetyFactorGammaM0() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM0.id] as number;
    }
    public set inSteelSafetyFactorGammaM0(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM0.id] = value;
    }

    public get inSteelSafetyFactorGammaM1() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM1.id] as number;
    }
    public set inSteelSafetyFactorGammaM1(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM1.id] = value;
    }

    public get inSteelSafetyFactorGammaMw() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMw.id] as number;
    }
    public set inSteelSafetyFactorGammaMw(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMw.id] = value;
    }

    public get inSteelSafetyFactorGammaMb() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMb.id] as number;
    }
    public set inSteelSafetyFactorGammaMb(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMb.id] = value;
    }

    public get shearLoadDistributionType() {
        return this.model[PropertyMetaData.Loads_ShearLoadDistributionType.id] as number;
    }

    public set shearLoadDistributionType(value) {
        this.model[PropertyMetaData.Loads_ShearLoadDistributionType.id] = value;
    }

    public updateProjectDesign(projectDesign: ProjectDesignBaseEntity) {
        this.designData.projectDesign = projectDesign;

        return this.designData.projectDesign;
    }

    public updateCalculateAllData(calculateAllData: ICalculateAllAnchor[]) {
        this.designData.calculateAllData = calculateAllData;

        return this.designData.calculateAllData;
    }

    public resolveCalculation() {
        this.loading = false;
        this.setPendingCalculation(false);

        this.calculateDefer.resolve({ design: this, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: {} as GenerateReportResponse } as ICalculationResult);
        this.calculateDefer = new Deferred<ICalculationResult>();

        CalculationServiceBase.cancelCalculationRequest(this, this.logger);
    }

    public onStructuralCalculationSoftwareChanged(fn: (design: Design, state: IDesignState) => void) {
        this.on(DesignEvent.structuralCalculationSoftwareChanged, fn);
    }

    public setSavedStateIdx() {
        this.savedStateIdx = this.currentStateIdx;
    }

    public setAnchorAISolutionSelected(isSelected : boolean){
        this.isAnchorAISolutionSelected = isSelected;
    }

    public setAnchorAISolutionSelectedOnce(isSelected : boolean){
        this.isAnchorAISolutionSelectedOnce = isSelected;
    }

    public addModelChange(propertyId: number, noCollapse?: boolean, value?: object, oldValue?: object) {
        this.addModelChangeInternal(propertyId, noCollapse, value, oldValue);

        return this.calculationService.calculateAsync(this);
    }

    public dispose() {
        CalculationServiceBase.cancelCalculationRequest(this, this.logger);

        this.calculateDefer.promise.catch(() => { /*Nothing to do*/ });
        this.calculateDefer.reject();
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.off(DesignEvent.stateChanged, this.stateChanged);
    }

    public hasETAG() {
        const c = this.designMethodGroupsAvailable.filter((item) => item.id == DesignMethodGroupEnum.ETAG || item.id == DesignMethodGroupEnum.SOFA).length;
        return this.designStandard.id == DesignStandardEnum.ETAG && c >= 2 && this.designType.id == DesignTypeId.Concrete;
    }

    public createSnapshot() {
        return {
            stateId: this.snapshotStateId,
            states: cloneDeep(this.states),
            currentState: this.states.findIndex((state) => state === this.currentState)
        } as ISnapshot;
    }

    public isStateSnapshot(snapshot: ISnapshot) {
        return this.snapshotStateId == snapshot.stateId;
    }

    public reloadState() {
        this.calculationService.loadState(this, this.states.findIndex((state) => state === this.currentState));
    }

    public getLanguageById(languageId: number) {
        return (this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[]).find(language => language.id == languageId);
    }

    public static getAnchorMetaProperties(designMethodGroupId?: number): IGlobalMetaProperties {
        return {
            isConcreteOverlay: false,
            productCodeListProperty: DesignMethodGroupHelper.IsLrfdBased(designMethodGroupId) ? DesignCodeList.AnchorFamilyAC58 : DesignCodeList.AnchorFamily,
            sourceMetaProperty: PropertyMetaData.AnchorLayout_AnchorFamily.id,
            sourceMetaPropertyApproval: PropertyMetaData.AnchorLayout_ViewApproval.id,
            infoSectionTranslationKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.Title'
        };
    }

    public static getRegionPe(design: DesignPe) {
        const regionId = design.region.id;
        const codeList = design.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        const region = codeList.find((r) => r.id == regionId);
        if (region == null) {
            throw new Error(`Unknown region '${regionId}'!`);
        }

        return region;
    }

    private getDesignStandardInternal() {
        const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];
        const designStandardId = this.model[PropertyMetaData.Option_DesignStandard.id] as number;
        const designStandard = codeList.find((ds) => ds.id == designStandardId);
        if (designStandard == null) {
            throw new Error(`Unknown design standard '${designStandardId}'!`);
        }
        return designStandard;
    }
}
