import cloneDeep from 'lodash-es/cloneDeep';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';

import { HttpRequest } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component, ElementRef, HostBinding, Input, NgZone, OnInit, TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';

import userInputsJson from './user_inputs.json';

import { AsadCalculateScopeCheckError, AsadIncludeFasteners, AsadOptimizeCase, AsadOptimizeCaseDetails, AsadOptimizeCaseOutput, AsadPoint } from '../../../shared/entities/design-pe';
import { CalculationCacheType, DownloadDesignInput as DownloadDesignRequest, IncludeFasteners, KernelCacheType, OptimizeDesignProgress, Point } from '../../../shared/entities/signalr';
import { environment } from '../../../environments/environmentPe';
import { UserService } from '../../services/user.service';
import { UnitService } from '../../services/unit.service';
import { ModalService } from '../../services/modal.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { AsadMapper, ModelChangesData } from '../../../shared/helpers/asad-mapper';
import { BrowserService } from '../../services/browser.service';
import { ApiService } from '../../services/api.service';
import { SignalRService } from '../../services/signalr.service';
import { includeSprites } from '../../sprites';

// format JSON in a way that it's easier to input fastenerIDs
const userInputs = JSON.stringify(userInputsJson, undefined, 2)
    .replace(/"fastenerIDs": \[\]/gi, '"fastenerIDs": [\n    \n  ]');

const enum Include {
    logs
}

interface DownloadDesignData {
    embedmentDepth: number;
    anchorCoordinates: AsadPoint[];
    anchorPlateWidth: number;
    anchorPlateHeight: number;
    profileEccentricityX: number;
    profileEccentricityY: number;
    fillHoles: boolean;
    isEdgeXNegativeReinforced: boolean;
    isEdgeXPositiveReinforced: boolean;
    isEdgeYNegativeReinforced: boolean;
    isEdgeYPositiveReinforced: boolean;
    baseMaterialEdgeXNegative?: number;
    baseMaterialEdgeXPositive?: number;
    baseMaterialEdgeYNegative?: number;
    baseMaterialEdgeYPositive?: number;
    fastenerId: number;
}

enum OptimizeCasesSortBy {
    default,
    area,
    evaluations
}

@Component({
    templateUrl: './asad-optimize-modal.component.html',
    styleUrls: ['./asad-optimize-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AsadOptimizeModalComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    @ViewChild('fileInput')
    public fileInput!: ElementRef<HTMLInputElement>;

    @HostBinding('class.fullscreen')
    public logsFullscreen = false;

    public optimizing = false;
    public lengthUnitString = '';

    public generic!: string;
    public optimizeCases!: AsadOptimizeCase[];
    public scopeCheckErrors!: AsadCalculateScopeCheckError[];

    public fastenersRadio!: Pick<RadioButtonProps<AsadIncludeFasteners>, 'items' | 'selectedValue'>;
    public includeCheckbox!: Pick<CheckboxButtonProps<Include>, 'items' | 'selectedValues'>;
    public kernelCacheRadio!: Pick<RadioButtonProps<KernelCacheType>, 'items' | 'selectedValue'>;
    public calculationCacheRadio!: Pick<RadioButtonProps<CalculationCacheType>, 'items' | 'selectedValue'>;
    public profileCodeCheckbox!: Pick<CheckboxButtonProps<boolean>, 'items' | 'selectedValues'>;
    public kernelFileCacheCheckbox!: Pick<CheckboxButtonProps<boolean>, 'items' | 'selectedValues'>;
    public timeEstimationRefreshNumericTextBox!: Pick<NumericTextBoxProps, 'value'>;
    public optimizeCasesSortByDropdown!: Pick<DropdownProps<OptimizeCasesSortBy>, 'items' | 'selectedValue'>;

    public cancelOptimization?: () => void;

    public keys = Object.keys.bind(Object);
    public min = Math.min.bind(Math);
    public debugControls = environment.asadDebugControls;

    private mapper: AsadMapper;

    constructor(
        private ngZone: NgZone,
        private signalRService: SignalRService,
        private userService: UserService,
        private unitService: UnitService,
        private apiService: ApiService,
        private browserService: BrowserService,
        private modalService: ModalService,
        private calculationService: CalculationServicePE,
        private elementRef: ElementRef<HTMLElement>,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.mapper = new AsadMapper();
    }

    public get input() {
        return this.userService.design.designData.asadData.input;
    }

    public get output() {
        return this.userService.design.designData.asadData.output;
    }

    public get allLogsLength() {
        if (this.output == null) {
            return 0;
        }

        return this.output.logs.length +
            Object.values(this.output.optimizeCases).reduce((a, b) => a + b.logs.length, 0);
    }

    public get scrollbarWidth() {
        return this.browserService.scrollbarWidth;
    }

    public trackByIndex: TrackByFunction<any> = (index: number) => index;

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-hilti-styled-checkbox',
            'sprite-arrow-down',
            'sprite-info',
        );

        this.lengthUnitString = this.unitService.getUnitStrings(Unit.mm)[0];

        this.generic = this.input.generic ?? '';
        this.optimizeCases = this.sortOptimizeCases(Object.values(this.output?.optimizeCases ?? {}), OptimizeCasesSortBy.default);
        this.scopeCheckErrors = this.output?.scopeCheckErrors ?? [];

        this.includeCheckbox = {
            items: [
                {
                    text: 'Logs',
                    value: Include.logs
                }
            ],
            selectedValues: new Set()
        };

        this.fastenersRadio = {
            items: [
                {
                    text: 'JSON',
                    value: AsadIncludeFasteners.jsonOrSelected
                },
                {
                    text: 'UI',
                    value: AsadIncludeFasteners.ui
                },
                {
                    text: 'All',
                    value: AsadIncludeFasteners.all
                }
            ],
            selectedValue: AsadIncludeFasteners.jsonOrSelected
        };

        if (this.input.includeLogs) {
            this.includeCheckbox.selectedValues?.add(Include.logs);
        }

        this.fastenersRadio.selectedValue = this.input.includeFasteners ?? AsadIncludeFasteners.jsonOrSelected;

        this.timeEstimationRefreshNumericTextBox = {
            value: this.input?.timeEstimationRefresh
        };

        this.profileCodeCheckbox = {
            items: [{
                text: 'Profile code',
                value: true
            }],
            selectedValues: new Set([false])
        };

        this.kernelCacheRadio = {
            items: [
                {
                    text: 'None',
                    value: KernelCacheType.None
                },
                {
                    text: 'Record',
                    value: KernelCacheType.Record
                },
                {
                    text: 'Replay',
                    value: KernelCacheType.Replay
                }
            ],
            selectedValue: KernelCacheType.None
        };

        this.kernelFileCacheCheckbox = {
            items: [{
                text: 'Use file cache',
                value: true
            }],
            selectedValues: new Set([false])
        };

        this.calculationCacheRadio = {
            items: [
                {
                    text: 'None',
                    value: CalculationCacheType.None
                },
                {
                    text: 'Record',
                    value: CalculationCacheType.Record
                },
                {
                    text: 'Replay',
                    value: CalculationCacheType.Replay
                }
            ],
            selectedValue: CalculationCacheType.None
        };

        this.optimizeCasesSortByDropdown = {
            items: [
                {
                    text: 'Default',
                    value: OptimizeCasesSortBy.default
                },
                {
                    text: 'Area',
                    value: OptimizeCasesSortBy.area
                },
                {
                    text: 'Evaluations',
                    value: OptimizeCasesSortBy.evaluations
                }
            ],
            selectedValue: OptimizeCasesSortBy.default
        };

        // do not close modal if optimization is in progress
        this.modalInstance.setOnClosing(() => this.optimizing ? false : true);
    }

    public onOptimizeCasesSortByChange(sortByValue: OptimizeCasesSortBy) {
        this.optimizeCasesSortByDropdown.selectedValue = sortByValue;

        if (this.output) {
            this.optimizeCases = this.sortOptimizeCases(Object.values(this.output.optimizeCases), sortByValue);
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public formatPercentage(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Percentage);
    }

    public formatLength(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Length);
    }

    public formatLengthWithoutUnit(value: number) {
        return this.formatWithoutUnit(value, UnitGroup.Length);
    }

    public formatArea(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Area);
    }

    public formatWithoutUnit(value: number, unitGroup: UnitGroup) {
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), Unit.None);
    }

    public async optimize() {
        this.optimizing = true;
        const optimizationStart = performance.now();

        this.optimizeCases = [];
        this.scopeCheckErrors = [];

        const clonedProjectDesign = cloneDeep(this.userService.design.designData.projectDesign);
        if (!clonedProjectDesign) {
            return;
        }

        this.userService.design.designData.asadData = {
            input: {
                useLambda: this.userService.design.designData.asadData.input.useLambda,
                projectDesign: clonedProjectDesign,
                generic: this.generic,
                includeLogs: this.includeCheckbox.selectedValues?.has(Include.logs) ?? false,
                timeEstimationRefresh: this.timeEstimationRefreshNumericTextBox.value ?? 0,
                includeFasteners: this.fastenersRadio.selectedValue ?? AsadIncludeFasteners.jsonOrSelected,
                includeRandomErrors: false,
                includeScValidation: false,
                includeTimeEstimation: false
            },
            output: {
                maxOptimizeCasesCount: 0,
                totalCalculationCount: 0,
                totalTime: 0,
                calculationCount: 0,
                runningOptimizationsCount: 0,
                logs: '',
                optimizationDate: Date.now(),
                optimizeMessages: [],
                timeEstimationUpdate: { progress: 0, remainingCalculationTime: 0 },
                timeEstimationCalculation: { progress: 0, estimatedCalculationCount: 0 },
                timeEstimationAverage: { progress: 0, estimatedCalculationCount: 0 },
                timeEstimations: [],
                optimizeCases: {},
                scopeCheckErrors: [],
                doneCasesCount: 0,
                totalCasesCount: 0,
                feasibleCasesCount: 0,
                errorCasesCount: 0
            }
        };

        const output = this.userService.design.designData.asadData.output;
        if (!output) {
            throw new Error('output not defined');
        }

        const cancel = new Promise<void>(resolve => {
            this.cancelOptimization = () => resolve();
        });

        const onProgress = (optimizeDesignProgress: OptimizeDesignProgress) => {
            const now = performance.now();

            this.mapper.mapOptimizeDesignProgress(this.output, optimizeDesignProgress);

            this.optimizeCases = this.sortOptimizeCases(Object.values(output.optimizeCases), this.optimizeCasesSortByDropdown.selectedValue ?? OptimizeCasesSortBy.default);
            this.scopeCheckErrors = output.scopeCheckErrors;

            const timeEstimation = last(output.timeEstimations);
            if (timeEstimation != null && this.output) {
                // time estimation - update
                const remainingCalculationTime = Math.max(timeEstimation.remaining - (now - timeEstimation.receiveTimestamp) / 1000, 0);
                this.output.timeEstimationUpdate = {
                    remainingCalculationTime,
                    progress: (timeEstimation.remaining - remainingCalculationTime + timeEstimation.passed) / (timeEstimation.passed + timeEstimation.remaining)
                };

                // time estimation - calculation
                this.output.timeEstimationCalculation = {
                    estimatedCalculationCount: timeEstimation.estimatedCalculationCount,
                    progress: Math.min(output.totalCalculationCount / timeEstimation.estimatedCalculationCount, 1)
                };
            }

            // time estimation - average
            const optimizeCases = Object.values(output.optimizeCases);
            const doneOptimizeCases = optimizeCases.filter(x => x.optimizeCaseOutput != null);

            if (doneOptimizeCases.length > 0) {
                const includesValidOutput = doneOptimizeCases.some(x => x.optimizeCaseOutput?.exitFlag != null && x.optimizeCaseOutput.exitFlag >= 0);

                // only calculate the average when we include at leat one valid result
                if (includesValidOutput && this.output) {
                    const doneOptimizeCasesCalculationCount = doneOptimizeCases.reduce((a, b) => a + b.calculationCount, 0);
                    const averageOptimizeCaseCalculationCount = doneOptimizeCasesCalculationCount / doneOptimizeCases.length;
                    const estimatedCalculationCount = Math.round(averageOptimizeCaseCalculationCount * output.maxOptimizeCasesCount);

                    this.output.timeEstimationAverage = {
                        estimatedCalculationCount,
                        progress: Math.min(output.totalCalculationCount / estimatedCalculationCount, 1)
                    };
                }
            }

            output.totalTime = now - optimizationStart;
        };

        try {
            const optimizeDesignOutput = await this.signalRService.asad.asadOptimize({
                IsFromUI: false,
                UseLambda: this.input.useLambda,
                Design: this.input.projectDesign,
                Generic: this.input.generic,
                IncludeLogs: this.input.includeLogs,
                IncludeFasteners: this.mapToIncludeFasteners(this.input.includeFasteners),
                ProfileCode: this.profileCodeCheckbox.selectedValues?.has(true),
                KernelCache: this.kernelCacheRadio.selectedValue,
                KernelFileCache: this.kernelFileCacheCheckbox.selectedValues?.has(true),
                CalculationCache: this.calculationCacheRadio.selectedValue,
                IncludeInvalidOptimizeCaseOutputs: true
            }, {
                cancel,
                onProgress: (optimizeDesignProgress: OptimizeDesignProgress) => {
                    onProgress(optimizeDesignProgress);

                    this.changeDetectorRef.detectChanges();
                }
            });

            if (optimizeDesignOutput.LastProgress != null) {
                onProgress(optimizeDesignOutput.LastProgress);
            }

            if (this.output) {
                this.output.timeEstimationUpdate = {
                    remainingCalculationTime: 0,
                    progress: 1
                };

                this.output.timeEstimationCalculation.progress = 1;
                this.output.timeEstimationAverage.progress = 1;
            }
        }
        finally {
            output.totalTime = performance.now() - optimizationStart;

            this.optimizing = false;
            this.cancelOptimization = undefined;
        }
    }

    public selectGenericInput() {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }

    public fileSelected() {
        if (this.fileInput.nativeElement.files?.length == 1) {
            const file = this.fileInput.nativeElement.files[0];

            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                this.generic = fileReader.result as string;
            });
            fileReader.readAsText(file);
        }
    }

    public setDefaultGenericInput() {
        this.generic = userInputs;
    }

    public clearGenericInput() {
        this.generic = '';
    }

    public saveGenericInput() {
        this.download('optimize-input.txt', this.generic);
    }

    public readonly optimizeCaseOutputKeys: (keyof AsadOptimizeCaseOutput)[] = [
        'area',
        'exitFlag',
        'constraintEvaluationsCount',
        'anchorCoordinates',
        'anchorPlateWidth',
        'anchorPlateHeight',
        'profileEccentricityX',
        'profileEccentricityY',
        'embedmentDepth',
        'utilizations',
        'isEdgeXNegativeReinforced',
        'isEdgeXPositiveReinforced',
        'isEdgeYNegativeReinforced',
        'isEdgeYPositiveReinforced',
        'baseMaterialEdgeXNegative',
        'baseMaterialEdgeXPositive',
        'baseMaterialEdgeYNegative',
        'baseMaterialEdgeYPositive',
        'fillHoles',
        'TCO',
        'BaseplateCosts',
        'AnchorsCosts',
        'InstallationCosts'
    ];

    public readonly optimizeCaseDetailsKeys: (keyof AsadOptimizeCaseDetails)[] = [
        'fastenerId',
        'layoutNumber',
        'numberOfAnchors',
        'fastenerFamilyId',
        'isAtToolAllowed',
        'isAutoCleaningAllowed',
        'engineeringValue'
    ];

    private getOptimizeDesignResults() {
        function hasValue(value: any) {
            return value != null &&
                (!Array.isArray(value) || value.length > 0) &&
                (typeof value != 'object' || Object.values(value ?? {}).length > 0);
        }

        if (!this.output) {
            return;
        }

        const optimizeDesignResults = Object.values(this.output.optimizeCases)
            .filter(x => x.optimizeCaseOutput != null && x.optimizeCaseDetails != null)
            .map(x => {
                const optimizeCaseOutput = Object.fromEntries(Object.entries(x.optimizeCaseOutput ?? {})
                    .filter(([key, value]: [any, any]) =>
                        this.optimizeCaseOutputKeys.includes(key) && hasValue(value)));

                const optimizeCaseDetails = Object.fromEntries(Object.entries(x.optimizeCaseDetails ?? {})
                    .filter(([key, value]: [any, any]) =>
                        this.optimizeCaseDetailsKeys.includes(key) && hasValue(value)));

                return {
                    ...optimizeCaseDetails,
                    ...optimizeCaseOutput
                };
            });

        return optimizeDesignResults;
    }

    public saveResults() {
        const optimizeDesignResultsJson = JSON.stringify(this.getOptimizeDesignResults(), undefined, 2);

        if (!optimizeDesignResultsJson || !this.output) {
            return;
        }

        this.download(`optimize-results-${this.output.optimizationDate}.json`, optimizeDesignResultsJson);
    }

    public exportToCsv() {
        const keys = [...this.optimizeCaseOutputKeys, ...this.optimizeCaseDetailsKeys];
        const items = this.getOptimizeDesignResults();

        if(!items || !this.output) {
            return;
        }

        const replacer = (key: string, value: any) => value === null ? '' : value;
        const optimizeDesignResultsCsv = [
            keys.join(','),
            ...items.map(row => keys.map(fieldName => '"' + JSON.stringify(row[fieldName], replacer)?.replace(/"/g, '""') + '"').join(','))
        ].join('\r\n');

        this.download(`optimize-results-${this.output.optimizationDate}.csv`, optimizeDesignResultsCsv);
    }

    public saveScopeCheckErrors() {
        const pickKeys: (keyof AsadCalculateScopeCheckError)[] = [
            'calculationCount',
            'scopeChecks',
            'fastenerId',
            'anchorCoordinates',
            'anchorPlateWidth',
            'anchorPlateHeight',
            'profileEccentricityX',
            'profileEccentricityY',
            'embedmentDepth',
            'isEdgeXNegativeReinforced',
            'isEdgeXPositiveReinforced',
            'isEdgeYNegativeReinforced',
            'isEdgeYPositiveReinforced',
            'baseMaterialEdgeXNegative',
            'baseMaterialEdgeXPositive',
            'baseMaterialEdgeYNegative',
            'baseMaterialEdgeYPositive',
            'fillHoles'
        ];
        const scopeCheckErrors = this.output?.scopeCheckErrors.map(result => Object.fromEntries(Object.entries(result).filter(([key, value]: [any, any]) => pickKeys.includes(key) && value != null)));
        const scopeCheckErrorsJson = JSON.stringify(scopeCheckErrors, undefined, 2);

        this.download(`optimize-sc-${this.output?.optimizationDate}.json`, scopeCheckErrorsJson);
    }

    public saveOptimizeMessages() {
        this.download(`optimize-messages-${this.output?.optimizationDate}.json`, JSON.stringify(this.output?.optimizeMessages, undefined, 2));
    }

    public saveLogs() {
        this.download(`optimize-logs-general-${this.output?.optimizationDate}.txt`, this.output?.logs);
    }

    public saveAllLogs() {
        let logs = this.output?.logs;
        for (const optimizeCase of Object.values(this.output?.optimizeCases ?? {})) {
            const optimizeCaseDetails = optimizeCase.optimizeCaseDetails;
            logs += `\n\n*** OptimizeCase - FastenerId: ${optimizeCaseDetails?.fastenerId}, NumberOfAnchors:${optimizeCaseDetails?.numberOfAnchors}, LayoutNumber:${optimizeCaseDetails?.layoutNumber} ***\n\n${optimizeCase.logs}`;
        }

        this.download(`optimize-logs-${this.output?.optimizationDate}.txt`, logs);
    }

    public saveTimeEstimation() {
        this.download(`optimize-time-estimations-${this.output?.optimizationDate}.json`, JSON.stringify(this.output?.timeEstimations, undefined, 2));
    }

    public async applyOptimizeCaseDesign(optimizeCase: AsadOptimizeCase) {
        const { optimizeCaseOutput, optimizeCaseDetails } = optimizeCase;
        if (!optimizeCaseOutput || !optimizeCaseDetails) {
            throw new Error('data not defined');
        }

        const data: ModelChangesData = {
            ...optimizeCaseOutput,
            fastenerId: optimizeCaseDetails?.fastenerId,
            variableEmbedmentDepth: optimizeCaseDetails?.variableEmbedmentDepth
        };

        const modelChanges = this.mapper.createModelChanges(this.calculationService, this.userService, data);

        if (modelChanges.hasChanges) {
            await modelChanges.calculate();

            this.close();
        }
    }

    public async applyScopeCheckErrorDesign(scopeCheckError: AsadCalculateScopeCheckError) {
        const data: ModelChangesData = {
            ...scopeCheckError,
            variableEmbedmentDepth: true
        };

        const modelChanges = this.mapper.createModelChanges(this.calculationService, this.userService, data);

        if (modelChanges.hasChanges) {
            await modelChanges.calculate();

            this.close();
        }
    }

    public async downloadOptimizeCaseDesign(optimizeCase: AsadOptimizeCase) {
        const { optimizeCaseOutput, optimizeCaseDetails } = optimizeCase;
        if (!optimizeCaseDetails || !optimizeCaseOutput) {
            throw new Error('data not defined');
        }

        const data: DownloadDesignData = {
            ...optimizeCaseOutput,
            fastenerId: optimizeCaseDetails?.fastenerId
        };

        await this.downloadDesign(data, `optimize-design-${this.output?.optimizationDate}-${optimizeCaseDetails.fastenerId}-${optimizeCaseDetails.numberOfAnchors}-${optimizeCaseDetails.layoutNumber}.pe`);
    }

    public async downloadScopeCheckErrorDesign(scopeCheckError: AsadCalculateScopeCheckError) {
        const data: DownloadDesignData = {
            ...scopeCheckError
        };

        await this.downloadDesign(data, `optimize-sc-${this.output?.optimizationDate}-${scopeCheckError.calculationCount}.pe`);
    }

    public openOptimizeCaseDetails(optimizeCase: AsadOptimizeCase) {
        this.modalService.openAsadOptimizeDetailsModal({
            optimizeCase,
            optimizationDate: this.output?.optimizationDate ?? 0,
            includeLogs: this.input.includeLogs ?? false
        });
    }

    public openScopeCheckErrorDetails(scopeCheckError: AsadCalculateScopeCheckError) {
        this.modalService.openAsadOptimizeScopeCheckDetailsModal({
            scopeCheckError
        });
    }

    private sortOptimizeCases(optimizeCases: AsadOptimizeCase[], optimizeCasesSortBy: OptimizeCasesSortBy) {
        switch (optimizeCasesSortBy) {
            case OptimizeCasesSortBy.default:
                return sortBy(optimizeCases, x => x.optimizeCaseDetails?.optimizeCaseIndex);
            case OptimizeCasesSortBy.area:
                return this.sortArea(optimizeCases);
            case OptimizeCasesSortBy.evaluations:
                return this.sortEvaluations(optimizeCases);
            default:
                throw new Error('unknown OptimizeCasesSortBy');
        }
    }

    private sortArea(optimizeCases: AsadOptimizeCase[]) {
        return sortBy(optimizeCases, x => {
            if (x.optimizeCaseOutput?.exitFlag != null && x.optimizeCaseOutput.exitFlag < 0) {
                return Number.MIN_SAFE_INTEGER;
            }

            return x.optimizeCaseOutput?.area ?? Number.MAX_SAFE_INTEGER;
        });
    }

    private sortEvaluations(optimizeCases: AsadOptimizeCase[]) {
        return sortBy(optimizeCases, x => {
            if (x.optimizeCaseOutput?.exitFlag != null && x.optimizeCaseOutput.exitFlag < 0) {
                return Number.MIN_SAFE_INTEGER;
            }

            if (x.optimizeCaseOutput?.constraintEvaluationsCount != null) {
                return -x.optimizeCaseOutput.constraintEvaluationsCount;
            }

            return Number.MAX_SAFE_INTEGER;
        });
    }

    private async downloadDesign(data: DownloadDesignData, fileName: string) {
        const request: DownloadDesignRequest = {
            AnchorCoordinates: data.anchorCoordinates.map((x): Point => ({ X: x.x, Y: x.y })),
            AnchorPlateHeight: data.anchorPlateHeight,
            AnchorPlateWidth: data.anchorPlateWidth,
            BaseMaterialEdgeXNegative: data.baseMaterialEdgeXNegative,
            BaseMaterialEdgeXPositive: data.baseMaterialEdgeXPositive,
            BaseMaterialEdgeYNegative: data.baseMaterialEdgeYNegative,
            BaseMaterialEdgeYPositive: data.baseMaterialEdgeYPositive,
            Design: this.input.projectDesign,
            EmbedmentDepth: data.embedmentDepth,
            FastenerId: data.fastenerId,
            FillHoles: data.fillHoles,
            IsEdgeXNegativeReinforced: data.isEdgeXNegativeReinforced,
            IsEdgeXPositiveReinforced: data.isEdgeXPositiveReinforced,
            IsEdgeYNegativeReinforced: data.isEdgeYNegativeReinforced,
            IsEdgeYPositiveReinforced: data.isEdgeYPositiveReinforced,
            ProfileEccentricityX: data.profileEccentricityX,
            ProfileEccentricityY: data.profileEccentricityY
        };

        const url = environment.asadServiceUrl + 'DownloadDesign';
        const response = await this.apiService.request<Blob>(new HttpRequest('POST', url, request, {
            responseType: 'blob'
        }));

        if (response.body) {
            this.browserService.downloadBlob(response.body, fileName, false, false);
        }
    }

    private download(name: string, content?: string) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content ?? ''));
        element.setAttribute('download', name);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    private mapToIncludeFasteners(includeFasteners: AsadIncludeFasteners): IncludeFasteners {
        switch (includeFasteners) {
            case AsadIncludeFasteners.jsonOrSelected:
                return IncludeFasteners.JsonOrSelected;
            case AsadIncludeFasteners.ui:
                return IncludeFasteners.UI;
            case AsadIncludeFasteners.all:
                return IncludeFasteners.All;
            default:
                throw new Error('Unknown AsadIncludeFasteners');
        }
    }
}
